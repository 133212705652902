import {createSlice} from 'redux-starter-kit';
import {IOrder} from '../../model/IOrder';
import {PaymentMethodType} from '../../model/PaymentMethodType';
import {IPaymentMethodItem} from '../../model/IPaymentMethodItem';

export interface ICustomerAddress{
    countryCode: string | null,
    address: string
}

export interface ILinkDataState {
    order: IOrder | null;
    credential: string | null;
    contactEmail: string | null;
    customerName: string | null;
    customerAddress: ICustomerAddress | null;
    customerId: string | undefined;
    savePaymentMethod: boolean;
    paymentMethodType: PaymentMethodType,
    paymentMethod: any,
    customerPaymentMethods: any[];
    redirectData: any; // toDo interface
    validUntil: string | null;
    payMeOrderAmount: number | null;
    chargePaymentRef: string | null;
    transactionCode: string | null;
}

export interface IChangeOrder {
    readonly payload: {
        order: IOrder;
    };
}

export interface IChangeCredential {
    readonly payload: {
        credential: string | null;
    };
}

export interface IChangeContactEmail {
    readonly payload: {
        contactEmail: string | null;
    };
}

export interface IChangeCustomerName {
    readonly payload: {
        customerName: string | null;
    };
}

export interface IChangeCustomerAddress {
    readonly payload: {
        customerAddress: ICustomerAddress | null;
    };
}

export interface IChangeCustomerId {
    readonly payload: {
        customerId: string;
    };
}

export interface IChangeSavePaymentMethod {
    readonly payload: {
        savePaymentMethod: any;
    };
}

export interface IChangePaymentMethodType {
    readonly payload: {
        paymentMethodType: PaymentMethodType;
    };
}

export interface IChangePaymentMethod {
    readonly payload: {
        paymentMethod: string;
    };
}

export interface IChangeCustomerPaymentMethods {
    readonly payload: {
        customerPaymentMethods: IPaymentMethodItem[];
    };
}

export interface IChangeChargePaymentRef {
    readonly payload: {
        chargePaymentRef: string | null;
    };
}

export interface IChangeOrderAmount {
    readonly payload: {
        orderAmount: number | null;
    };
}

export interface IChangeRedirectData {
    readonly payload: {
        redirectData: any;
    };
}

export interface IChangeValidUntil {
    readonly payload: {
        validUntil: any;
    };
}

export interface IChangeTransactionCode {
    readonly payload: {
        transactionCode: string | null;
    };
}

const initialState: ILinkDataState = {
    order: null,
    credential: null,
    contactEmail: null,
    customerName: null,
    customerAddress: null,
    customerId: undefined,
    savePaymentMethod: false,
    paymentMethodType: PaymentMethodType.CARD,
    paymentMethod: null,
    customerPaymentMethods: [],
    redirectData: null,
    validUntil: null,
    payMeOrderAmount: null,
    chargePaymentRef: null,
    transactionCode: null
};

const linkDataSlice = createSlice({
    slice: "linkData",
    initialState: initialState,
    reducers: {
        changeOrder: {
            reducer: (state: ILinkDataState, action: IChangeOrder) => {
                return {
                    ...state,
                    order: action.payload.order,
                };
            },
            prepare(order: IOrder) {
                return {
                    payload: { order }
                };
            }
        },
        changeCredential: {
            reducer: (state: ILinkDataState, action: IChangeCredential) => {
                return {
                    ...state,
                    credential: action.payload.credential,
                };
            },
            prepare(credential: string | null) {
                return {
                    payload: { credential }
                };
            }
        },
        changeContactEmail: {
            reducer: (state: ILinkDataState, action: IChangeContactEmail) => {
                return {
                    ...state,
                    contactEmail: action.payload.contactEmail,
                };
            },
            prepare(contactEmail: string | null) {
                return {
                    payload: { contactEmail }
                };
            }
        },
        changeCustomerName: {
            reducer: (state: ILinkDataState, action: IChangeCustomerName) => {
                return {
                    ...state,
                    customerName: action.payload.customerName
                };
            },
            prepare(customerName: string | null) {
                return {
                    payload: { customerName }
                };
            }
        },
        changeCustomerAddress: {
            reducer: (state: ILinkDataState, action: IChangeCustomerAddress) => {
                return {
                    ...state,
                    customerAddress: action.payload.customerAddress
                };
            },
            prepare(customerAddress: ICustomerAddress | null) {
                return {
                    payload: { customerAddress }
                };
            }
        },
        changeCustomerId: {
            reducer: (state: ILinkDataState, action: IChangeCustomerId) => {
                return {
                    ...state,
                    customerId: action.payload.customerId,
                };
            },
            prepare(customerId: string) {
                return {
                    payload: { customerId }
                };
            }
        },
        changePayMeOrderAmount: {
            reducer: (state: ILinkDataState, action: IChangeOrderAmount) => {
                return {
                    ...state,
                    payMeOrderAmount: action.payload.orderAmount,
                };
            },
            prepare(orderAmount: number | null) {
                return {
                    payload: { orderAmount }
                };
            }
        },
        changeChargePaymentRef: {
            reducer: (state: ILinkDataState, action: IChangeChargePaymentRef) => {
                return {
                    ...state,
                    chargePaymentRef: action.payload.chargePaymentRef,
                };
            },
            prepare(chargePaymentRef: string | null) {
                return {
                    payload: { chargePaymentRef }
                };
            }
        },
        changeSavePaymentMethod: {
            reducer: (state: ILinkDataState, action: IChangeSavePaymentMethod) => {
                return {
                    ...state,
                    savePaymentMethod: action.payload.savePaymentMethod
                };
            },
            prepare(savePaymentMethod: boolean) {
                return {
                    payload: { savePaymentMethod }
                };
            }
        },
        changePaymentMethodType: {
            reducer: (state: ILinkDataState, action: IChangePaymentMethodType) => {
                return {
                    ...state,
                    paymentMethodType: action.payload.paymentMethodType
                };
            },
            prepare(paymentMethodType: PaymentMethodType) {
                return {
                    payload: { paymentMethodType }
                };
            }
        },
        changePaymentMethod: {
            reducer: (state: ILinkDataState, action: IChangePaymentMethod) => {
                return {
                    ...state,
                    paymentMethod: action.payload.paymentMethod
                };
            },
            prepare(paymentMethod: any) { // stripe.JSW interface
                return {
                    payload: { paymentMethod }
                };
            }
        },
        changeCustomerPaymentMethods: {
            reducer: (state: ILinkDataState, action: IChangeCustomerPaymentMethods) => {
                return {
                    ...state,
                    customerPaymentMethods: action.payload.customerPaymentMethods
                };
            },
            prepare(customerPaymentMethods: IPaymentMethodItem[]) {
                return {
                    payload: { customerPaymentMethods }
                };
            }
        },
        changeRedirectData: {
            reducer: (state: ILinkDataState, action: IChangeRedirectData) => {
                return {
                    ...state,
                    redirectData: action.payload.redirectData
                    
                };
            },
            prepare(redirectData: any) {
                return {
                    payload: { redirectData }
                };
            }
        },
        changeValidUntil: {
            reducer: (state: ILinkDataState, action: IChangeValidUntil) => {
                return {
                    ...state,
                    validUntil: action.payload.validUntil
                };
            },
            prepare(validUntil: string | null) {
                return {
                    payload: { validUntil }
                };
            }
        },
        changeTransactionCode: {
            reducer: (state: ILinkDataState, action: IChangeTransactionCode) => {
                return {
                    ...state,
                    transactionCode: action.payload.transactionCode
                };
            },
            prepare(transactionCode: string | null) {
                return {
                    payload: { transactionCode }
                };
            }
        },
        cleanState: {
            reducer: (state: ILinkDataState) => {
                return {
                    order: null,
                    credential: null,
                    contactEmail: null,
                    customerName: null,
                    customerAddress: null,
                    customerId: undefined,
                    setupIntent: null,
                    savePaymentMethod: false,
                    paymentMethodType: PaymentMethodType.CARD,
                    paymentMethod: null,
                    customerPaymentMethods: [],
                    redirectData: null,
                    validUntil: null,
                    payMeOrderAmount: null,
                    chargePaymentRef: null,
                    transactionCode: null
                };
            },
            prepare(payload: {}) {
                return {
                    payload: {}
                };
            }
        },
    }
});
export const {
    changeChargePaymentRef,
    changeOrder,
    changeCredential,
    changeContactEmail,
    changeCustomerName,
    changeCustomerAddress,
    changeCustomerId,
    changePayMeOrderAmount,
    changeSavePaymentMethod,
    changePaymentMethodType,
    changePaymentMethod,
    changeCustomerPaymentMethods,
    changeRedirectData,
    changeValidUntil,
    changeTransactionCode,
    cleanState,
} = linkDataSlice.actions;
export default linkDataSlice.reducer;