import React, {FC} from 'react';
import {connect} from 'react-redux';
import {changeChargePaymentRef} from '../../../store/reducers/linkDataSlice';
import {useTranslation} from 'react-i18next';

interface IChargePaymentRefInputProps {
    changeChargePaymentRef: typeof changeChargePaymentRef;
}

const ChargePaymentRefInput:FC<IChargePaymentRefInputProps> = (props: IChargePaymentRefInputProps) => {
    const { t } = useTranslation();

    const MAX_INPUT_LENGTH = 140;

    const onInputChange = (e: any) => {
        
        if (!e.target || !e.target.value || e.target.value === '') {
            props.changeChargePaymentRef(null);
            return;
        }

        props.changeChargePaymentRef(e.target.value);
    };

    return (
        <div className="formControl">
            <input id="customerName"
                   type="text"
                   placeholder={t('chargePaymentRefInput.placeholder')}
                   onChange={e => onInputChange(e)}
                   maxLength={MAX_INPUT_LENGTH}
            />
        </div>
    );
};

export default connect(
    () => { return {}; },
    {
        changeChargePaymentRef
    }
)(ChargePaymentRefInput);