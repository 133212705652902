import React from 'react';
import { WindowWithCustomAppConfig } from '../../../core';
import { IOrder } from '../../../model/IOrder';
import { RootState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { orderSelector } from '../../../store/selectors/linkDataSelectors';

export interface IDevtHinBoxProps {
    order: IOrder | null;
}

class DevHinBox extends React.Component<IDevtHinBoxProps> {


    render() {
        if ((window as WindowWithCustomAppConfig)?.$APP_CONFIG?.displayTestCard !== true) {
            return null;
        }

        if (!this.props.order) {
            return null;
        }

        if(this.props.order.type !== "payme") {
            return null;
        }

        return (
           <div>
               <div>test visa card: 4242424242424242</div>
               <div>test 3d secure: 4000002500003155</div>
               <div>test 3d secure: 4000002760003184</div>

               <div>test iban: IT40S0542811101000000123456</div>
           </div>
        )
    }
}

export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state)
        };
    },
    {
    }
)(DevHinBox);