import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {contactEmailSelector, orderSelector} from '../../../store/selectors/linkDataSelectors';
import {changeContactEmail} from '../../../store/reducers/linkDataSlice';
import i18n from '../../../i18n';
import ContactEmailInput from '../ContactEmailInput';
import { IOrder } from '../../../model/IOrder';

interface IOrderEmailNoteBoxProps {
    order: IOrder | null;
}

// source: https://stackoverflow.com/questions/46155/whats-the-best-way-to-validate-an-email-address-in-javascript
// dis-allow spaces in email and no double @
const emailRegex = new RegExp('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$');

class OrderEmailNoteBox extends React.Component<IOrderEmailNoteBoxProps> {

    private renderContactEmail() {
        if (!this.props.order?.contactRequest) {
            return null;
        }

        return (
            <div>
                <ContactEmailInput />
            </div>
        );
    }

    private renderNote() {
        if (!this.props.order?.confirmationPageNote ) {
            return null;
        }

        return (
            <div className='noteContainer mb-28'>
                { this.props.order.confirmationPageNote }
            </div>);
    }

    render() {
        if (!this.props.order?.contactRequest && !this.props.order?.confirmationPageNote) {
            return null;
        }

        return (
            <div className='mb-28 bt-01 bb-01'>
                <div className='mt-28'>
                { this.renderContactEmail() }
                { this.renderNote() }
                </div>
            </div>
        );
    }
}

export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state),
        };
    },
    {
        changeContactEmail
    }
)(OrderEmailNoteBox);