import 'reflect-metadata';
import React from 'react';
import {BrowserRouter as Router, Switch, Route,} from 'react-router-dom';
import {container, InjectionReduxProvider} from './ioc';
import './assets/styles/global.scss';
import styles from "./styles.module.scss";
import store from './store';
import Link2Pay from './components/Link2Pay';
import Error from './components/Common/Error';
import { BrowserNotCompatibleError } from './components/Common/Error/BrowserNotCompatible';
import ApiKeyActivated from './components/Common/ApiKeyActivated';
import Index from './components/Common/LanguageSelector'
import { InvalidUrlError } from './components/Common/Error/InvalidUrl';
import Link2Save from './components/Link2Save';
import PayMe from './components/PayMe';
import TspayDetailIcon from './components/Common/TspayDetailIcon';
import DevHinBox from './components/Common/DevHinBox';

export default function Main() {


  return (
      <Router>
          <InjectionReduxProvider store={store} container={container}>
            <div id="main">
                <div className={styles.viewContainer}>
                    <div className={styles.header}>
                        <div className={styles.logoContainer} />
                        <div className={styles.tools}>
                            <TspayDetailIcon />
                            <Index></Index>
                        </div>
                    </div>
                    <div id="header-space" className={styles.contentContainer}>
                        <Switch>
                            <Route path="/link2pay/:orderKey" component={Link2Pay} />
                            <Route path="/link2save/:orderKey" component={Link2Save} />
                            <Route path="/payme/:orderKey" component={PayMe} />
                            <Route path="/paymeC/:orderKey" component={PayMe} />
                            <Route path="/api-key-activated" component={ApiKeyActivated} />
                            <Route path="/error" component={Error} />
                            <Route path="/browser-not-compatible" component={BrowserNotCompatibleError} />
                            <Route path="/not-found" component={InvalidUrlError} />
                        </Switch>
                    </div>
                </div>

                <DevHinBox/>
            </div>
          </InjectionReduxProvider>
      </Router>
  );
}