import * as React from "react";
import { useTranslation } from "react-i18next";

interface ITranslateProps {
  readonly text: string;
}

const Translate = (props: ITranslateProps) => {
  const { t } = useTranslation();
  return <React.Fragment>{t(props.text)}</React.Fragment>;
};

export default Translate;
