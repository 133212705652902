import {IJson} from '../../core';
import { AbstractRestGetService } from './base';
import {inject} from 'inversify';
import {ErrorHandlerService, IErrorHandlerService} from '../errorHandlerService';

export class PaypalService extends AbstractRestGetService<IJson> {
    rootUrl: string = '/paypal/';

    @inject(ErrorHandlerService) errorHandlerService: IErrorHandlerService;

    protected get errorHandler(): any {
        return this.errorHandlerService;
    }

    createOrder(data: any, orderKey: string) {
        return this.post(`/${orderKey}/order`, data);
    }

    getClientToken() {
        return this.get(`token`);
    }
 }
