import React from 'react';
import styles from './styles.module.scss';
import Translate from '../../Translate';
import {TooltipType} from '../index';

interface ITooltipInfoProps {
    header: React.ReactFragment;
    content: React.ReactFragment;
    type: TooltipType;
}

interface ITooltipInfoState {
    height: number
}

class TooltipInfo extends React.Component<ITooltipInfoProps, ITooltipInfoState> {
    private divElement:React.Ref<HTMLInputElement>;

    constructor(props: ITooltipInfoProps) {
        super(props);

        this.divElement = React.createRef<HTMLInputElement>();

        this.state = {
            height: 0
        }
    }

    componentDidMount() {
        const tooltipInfo = document.getElementById('tooltipInfo');

        if (tooltipInfo) {
            this.setState({height: tooltipInfo.clientHeight})
        }
    }

    render() {
        const style = this.props.type === TooltipType.Web ? {top: -(this.state.height / 2 - 10)} : {};

        return (
            <div className={styles.infoContainer} id="tooltipInfo" style={style}>
                <div className={styles.info}>
                    <div className={styles.header}>
                        {this.props.header && this.props.header}
                    </div>
                    <div className={styles.content}>
                        {this.props.content}
                    </div>
                </div>
            </div>
        )
    }

}

export default TooltipInfo;