import React, { FC } from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {PaymentMethodType} from '../../../model/PaymentMethodType';
import PaymentMethodOption from '../PaymentMethodOption';
import Translate from '../Translate';
import styles from "./styles.module.scss";
import { IOrder } from '../../../model/IOrder';
import { contactEmailSelector, orderSelector, payMeOrderAmountSelector } from '../../../store/selectors/linkDataSelectors';
import { navigateToAcquirerDedicateWebApp } from '../../../core';
import { useInjection } from '../../../ioc';
import { ErrorHandlerService } from '../../../service';

interface IPaymentMethodOptionListProps {
    order: IOrder | null;
    contactEmail: string | null;
    payMeOrderAmount: number | null;
    cardRequiredAcquired?: string;
}

const PaymentMethodOptionList:FC<IPaymentMethodOptionListProps> =  (props: IPaymentMethodOptionListProps) => {
    const errorHandlerService = useInjection(ErrorHandlerService);

    if(props.order?.sourceTypes?.length === 0) {
        errorHandlerService.handleGenericError({code: "PAMENT_NOT_AVAILABLE_ACQUIRER"});
    }

    let disabledPayMethod: boolean =
        (props.order?.contactRequest && !props.contactEmail) ||
        (props.order?.type === "payme" && !props.payMeOrderAmount);

    const renderPayPalPayMethodIfAllowed = () => {
        const payMethodType = PaymentMethodType.PAYPAL;
        const order = props.order;

        if(order && order.sourceTypes?.includes(payMethodType)) {
            return <PaymentMethodOption
                        style={disabledPayMethod ? styles.disabledPayMethod : ''}
                        icon={styles.paypalIcon}
                        action={() => navigateToAcquirerDedicateWebApp(order, payMethodType)}
                    />;
        }
    }

    const renderCardPayMethodIfAllowed = () => {
        const payMethodType = PaymentMethodType.CARD;
        const order = props.order;

        if(order && order.sourceTypes?.includes(payMethodType)) {
            return <PaymentMethodOption
                        style={disabledPayMethod ? styles.disabledPayMethod : ''}
                        icon={styles.cardIcon}
                        title='paymentMethod.cardDesc'
                        action={() => navigateToAcquirerDedicateWebApp(order, payMethodType, props.cardRequiredAcquired)}
                    />;
        }
    }
    
    const renderSepaDebitPayMethodIfAllowed = () => {
        const payMethodType = PaymentMethodType.SEPA_DEBIT;
        const order = props.order;
        if(order && order.sourceTypes?.includes(payMethodType)) {
            return <PaymentMethodOption
                        style={disabledPayMethod ? styles.disabledPayMethod : ''}
                        icon={styles.sepaIcon}
                        title='paymentMethod.sepaDebitDesc'
                        action={() => navigateToAcquirerDedicateWebApp(order, payMethodType)}
                    />;
        }
    }

    return (
        <div className={styles.pmoListContainer}>
            <div className={styles.selectPayMethod}><Translate text={`paymentMethod.selectPayMethod`}/></div>
            {renderPayPalPayMethodIfAllowed()}
            {renderCardPayMethodIfAllowed()}
            {renderSepaDebitPayMethodIfAllowed()}
        </div>
    );
}

export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state),
            contactEmail: contactEmailSelector(state),
            payMeOrderAmount: payMeOrderAmountSelector(state)
        };
    },
    {
    }
)(PaymentMethodOptionList);