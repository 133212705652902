import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {contactEmailSelector} from '../../../store/selectors/linkDataSelectors';
import {changeContactEmail} from '../../../store/reducers/linkDataSlice';
import i18n from '../../../i18n';

interface IExternalContactEmailInputProps {
}

interface IConnectedContactEmailInputProps {
    contactEmail: string | null;
    changeContactEmail: typeof changeContactEmail;
}

interface IContactEmailInputProps extends IExternalContactEmailInputProps, IConnectedContactEmailInputProps {}

export interface IcontactEmailInputState {
    contactEmail: string | null;
    isValid: boolean;
    isTouched: boolean;
}

// source: https://stackoverflow.com/questions/46155/whats-the-best-way-to-validate-an-email-address-in-javascript
// dis-allow spaces in email and no double @
const emailRegex = new RegExp('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$');

class ContactEmailInput extends React.Component<IContactEmailInputProps, IcontactEmailInputState> {
    private validate(inputValue?: string | null) {  
        const isValid = inputValue ? emailRegex.test(inputValue) : false;

        if (isValid && inputValue) {
            this.props.changeContactEmail(inputValue);
        } else {
            this.props.changeContactEmail(null);
        }

        this.setState({isValid: isValid});
        return;

    };

    private onInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
        if (!e.currentTarget) {
            return;
        }

        // Do not allow for leading or trailing spaces for email
        const targetValue = e.currentTarget.value;
        const email = targetValue ? targetValue.trim() : null;

        this.setState({
            contactEmail: email
        });

        this.validate(email);
    };

    private onInputBlur = () => {
        if ( !this.state || !this.state.isTouched) {
            this.setState({isTouched: true});
        }
    };

    componentDidMount() {
        if (!this.props.contactEmail) {
            return;
        }

        this.setState({contactEmail: this.props.contactEmail})
    }

    render() {
        const value = this.state && this.state.contactEmail ? this.state.contactEmail : '',
            showError = this.state ? this.state.isTouched && !this.state.isValid : false;
        
        return (

            <div className="formControl">
                <input id="contactEmail"
                       type="text"
                       onChange={this.onInputChange}
                       onBlur={this.onInputBlur}
                       value={value}
                       placeholder={i18n.t('step.customerIdentification.enterYourEmail')}
                />
                <div>{ showError }</div>
            </div>
        );
    }
}

export default connect(
    (state: RootState) => {
        return {
            contactEmail: contactEmailSelector(state),
        };
    },
    {
        changeContactEmail
    }
)(ContactEmailInput);