import {injectable} from 'inversify';

export interface ILinkHelperService {
    getTOTPRequestPayload(credential: string): any
}

@injectable()
export class LinkHelperService implements ILinkHelperService {

    getTOTPRequestPayload(credential: string): any {
        if (credential[0] === '+') {
            return  { mobile: credential }
        } else {
            return  { email: credential }
        }
    }
}


