import { Container } from 'inversify';
import {
    AuthenticationDaoService,
    Link2PayDaoService,
    Link2SaveDaoService,
    HashDaoService,
    CustomerDaoService,
    PaypalService,
    PayMeDaoService
} from '../service/dao';
import {
    ErrorHandlerService,
} from '../service';

export const container = new Container();

container.bind<ErrorHandlerService>(ErrorHandlerService).to(ErrorHandlerService).inSingletonScope();

container.bind<Link2PayDaoService>(Link2PayDaoService).to(Link2PayDaoService).inSingletonScope();
container.bind<Link2SaveDaoService>(Link2SaveDaoService).to(Link2SaveDaoService).inSingletonScope();
container.bind<PayMeDaoService>(PayMeDaoService).to(PayMeDaoService).inSingletonScope();
container.bind<AuthenticationDaoService>(AuthenticationDaoService).to(AuthenticationDaoService).inSingletonScope();
container.bind<CustomerDaoService>(CustomerDaoService).to(CustomerDaoService).inSingletonScope();
container.bind<HashDaoService>(HashDaoService).to(HashDaoService).inSingletonScope();
container.bind<PaypalService>(PaypalService).to(PaypalService).inSingletonScope();

export {InjectionReduxProvider, useInjection} from './provider';
