import React, { FC } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store/reducers";
import queryString from 'querystring';
import { WindowWithCustomAppConfig } from "../../../core";

class ApiKeyActivated extends React.Component {
  componentDidMount() {
    // change page title
    document.title = 'TS Pay - Attivazione';
  }

  render() {

    // load url config
    const dashboardUrl = (window as WindowWithCustomAppConfig).$APP_CONFIG?.dashboardUrl || 'https://dashboard.tspay.app';

    // if ?/&state is provided in url run other actions
    if (
      'location' in this.props &&
      'search' in (this.props as any)?.location &&
      typeof (this.props as any)?.location?.search === 'string'
    ) {
      // pares query
      const queryStringPassed: string = (this.props as any).location.search
      const parsedQueryParams = queryString.parse(queryStringPassed.replace('?', ''));
      console.log('parsedQueryParams', parsedQueryParams)
      // get state
      const statePassed = parsedQueryParams?.state;
      
      // run actions based on state
      if (statePassed === 'failed') {
        // return generic error
        return (
          <div className="content errorContainer">
            <div className="mb-36 errorMessage">
                Spiacente, si è verificato un errore. Ti preghiamo di riprovare.
            </div>
          </div>
        );
      }
      if(statePassed === 'isnotowner'){
        return (
          <div className="content errorContainer">
            <div className="mb-36 errorMessage">
              Spiacente, solo l'utente proprietario può attivare il software gestionale.
            </div>
          </div>
        );
      }

      if(statePassed === 'isactive'){
        // the apiKey was previously activated
        return (
          <div className="content errorContainer">
            <div className="mb-36 errorMessage">
              Grazie, questo software gestionale è già stato attivato.
            </div>

            <div className="mb-36 errorMessage subtitle">
              Ora puoi accedere al portale per impostare il conto di accredito,
              verificare le transazioni e collegare i tuoi conti correnti:
              {" "}
              <a href={dashboardUrl} target="_blank">{dashboardUrl}</a>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="content errorContainer">
        <div className="mb-36 errorMessage">
          Grazie, il tuo software gestionale è stato attivato.
        </div>

        <div className="mb-36 errorMessage subtitle">
          Ora puoi accedere al portale per impostare il conto di accredito,
          verificare le transazioni e collegare i tuoi conti correnti:
          {" "}
          <a href={dashboardUrl} target="_blank">{dashboardUrl}</a>
        </div>
      </div>
    );
  }
}

export default connect((state: RootState) => {
  return {};
})(ApiKeyActivated);
