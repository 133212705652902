import React, {FC} from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {orderSelector, redirectDataSelector} from '../../../store/selectors/linkDataSelectors';
import {IOrder} from '../../../model/IOrder';
import {HashDaoService} from '../../../service/dao';
import {errorSelector, languageSelector} from '../../../store/selectors/sagaSelectors';
import {first, tap} from 'rxjs/operators';
import {ErrorType, IError} from '../../../model/IError';
import {useInjection} from '../../../ioc';
import { LanguageLocale } from '../../../constants/locales';

export interface IErrorProps {
    order: IOrder | null;
    error: IError;
    redirectData: any;
    activeLanguage: LanguageLocale;
}

const Error:FC<IErrorProps> = (props: IErrorProps) => {
    console.log('props', props);

    const hashDaoService = useInjection(HashDaoService);

    const order = props.order as IOrder;

    const handleStripeError = (url: string) => {
        if (!props.order || !props.redirectData) {
            return (
                <div className="content">
                    <div className="errorMessage">Spiacente, si è verificato un errore. Ti preghiamo di riprovare.</div>
                </div>
            );
        }

        const now = new Date();
        const timestamp = now.toISOString();

        const getHashPayload: any = {
            orderKey: order.orderKey,
            state: props.redirectData.state,
            timestamp,
            // add expectKey if present (chargeKey/sourceKey)
            expectKey: props.redirectData?.expectKey,
            // add stripe decline code if set
            declineCode: typeof props?.error?.errorDetails?.declineCode === 'string' ?
                                props.error.errorDetails.declineCode : undefined,
            // add stripe errorKey if set
            errorKey: typeof props?.error?.errorDetails?.errorKey === 'string' ?
                                props.error.errorDetails.errorKey : undefined,
            // add error message if set
            errorMessage: props?.error?.errorDetails?.description
        };
            
        // add selectedLangLocale only if present
        if (typeof props.activeLanguage === 'string') {
            getHashPayload.selectedLangLocale = props.activeLanguage;
        }

        hashDaoService.getHash(getHashPayload).pipe(
            first(),
            tap((resp: any) => {
                // for infering - this function is executed only if callbackUrl or cancelUrl is set
                // canceled is set to true only when cancel button is clicked
                if (!url) {
                    return;
                }

                const token = resp.token;
                // add token as 'data' to callbackUrl
                window.location.href = `${url}${url.indexOf("?") === -1 ? "?" : "&" }data=${token}`;
            })
        ).subscribe();

        return (
            <div className="content">
                <h1 className="errorMessage">Redirecting...</h1>
            </div>
        );
    };

    if (!props.error) {
        return (
            <div className="content">
                <h1 className="title">Welcome to error page</h1>
            </div>
        );
    }

    // This is required to handle cancel button events
    if (props.redirectData?.canceled && order?.cancelUrl) {
        return handleStripeError(order?.cancelUrl);
    }

    // This handle error coming from stripe payment method
    if (props.error.type === ErrorType.Stripe && order?.callbackUrl) {
        return handleStripeError(order?.callbackUrl);
    }

    return (
        <div className="content errorContainer">
            <h1 className="errorTitle">{ typeof props?.error?.customErrorTitle === 'string' ?  props.error.customErrorTitle : ''}</h1>
            <div className="mb-36 errorMessage">
                { typeof props?.error?.message === 'string' ? props?.error?.message : 'Spiacente, si è verificato un errore. Ti preghiamo di riprovare.'}
            </div>

            <div className="mt-10 errorMessage subtitle">
                {
                    typeof props?.error?.errorDetails?.declineCode === 'string' ? props?.error?.errorDetails?.declineCode : ''
                }

            </div>

            <div className="mt-5 errorMessage subtitle">
                {
                    typeof props?.error?.errorDetails?.description === 'string' ? props?.error?.errorDetails?.description : ''
                }
            </div>
            {
                /*
                // refresh button not needed
            <button className="btn btn-secondary mb-36"
                    type="button"
                    onClick={e => window.location.reload(false)}>
                <Translate text={`button.refresh`}/>
            </button>
                */
            }
        </div>
    );
};

export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state),
            error: errorSelector(state),
            redirectData: redirectDataSelector(state),
            activeLanguage: languageSelector(state),
        };
    },
)(Error);
