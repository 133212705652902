import {
    Action,
    configureStore,
    getDefaultMiddleware
} from "redux-starter-kit";
import rootReducer, { RootState } from "./reducers";
import { ThunkAction } from "redux-thunk";
import { createEpicMiddleware } from "redux-observable";
import { rootEpic } from "./epics";

const defaultMiddleware = getDefaultMiddleware(),
    epicMiddleware = createEpicMiddleware();

defaultMiddleware.push(epicMiddleware);

const store = configureStore({
    reducer: rootReducer,
    middleware: defaultMiddleware
});

epicMiddleware.run(rootEpic);

const hotModule = module as typeof module & { hot: any };
if (process.env.NODE_ENV === "development" && hotModule.hot) {
    hotModule.hot.accept("./reducers", () => {
        const newRootReducer = require("./reducers").default;
        store.replaceReducer(newRootReducer);
    });
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export default store;
