import React from 'react';
import Translate from '../Translate';
import Moment from 'react-moment';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {orderSelector} from '../../../store/selectors/linkDataSelectors';
import {IOrder} from '../../../model/IOrder';
import i18n from '../../../i18n';
import { languageSelector } from '../../../store/selectors/sagaSelectors';

interface IExpirationInfoProps {
    order: IOrder | null;
}

class ExpirationInfo extends React.Component<IExpirationInfoProps> {

    render() {
        if (!this.props.order || !this.props.order.validityEndDate) {
            return null;
        }

        return (
            <div className="expiration">
                <span><Translate text={'step.paymentDetails.expiration'}/></span>
                <Moment format={'LL'} locale={i18n.language} unix>{this.props.order.validityEndDate}</Moment>
            </div>
        );
    }

}


export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state),
            language: languageSelector(state),
        };
    },
    {

    }
)(ExpirationInfo);