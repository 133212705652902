import React, {FC} from 'react';
import {connect} from 'react-redux';
import {changePayMeOrderAmount} from '../../../store/reducers/linkDataSlice';
import { LanguageLocale } from '../../../constants/locales';
import {RootState} from '../../../store/reducers';
import {useTranslation} from 'react-i18next';
import styles from "./styles.module.scss";
import CurrencySymbol from '../CurrencySymbol';
import CustomError from '../../Common/CustomError';
import Translate from '../Translate';
import CurrencyConverter from '../CurrencyConverter';
import { getPriceConverterConfigFromLanguage, IPriceConverterConfig } from '../../../core';

interface IOrderAmountInputProps {
    currency?: string;
    language?: LanguageLocale;
    config?: any;
    minChargeAmount: number | undefined;
    maxChargeAmount: number | undefined;
    changePayMeOrderAmount: typeof changePayMeOrderAmount;
}

const OrderAmountInput:FC<IOrderAmountInputProps> = (props: IOrderAmountInputProps) => {
    const { t } = useTranslation();

    // Allow to insert , or .
    // This because we cannot know the input keyboard virtual language
    const inputAmountRegex = /^(\s*|((?:[1-9][0-9]*|0)(((\,|\.)\d{0,2})?)))$/;

    const amountClass = props.config && props.config.amount ? props.config.amount.class : '';

    let config: IPriceConverterConfig = getPriceConverterConfigFromLanguage(props.language);

    // State
    const [amount, setAmount] = React.useState<string | undefined>(undefined);
    const [isAmountUnderMinAmount, setIsAmountUnderMinAmount] = React.useState(false);
    const [isAmountOverMaxAmount, setIsAmountOverMaxAmount] = React.useState(false);

    const onInputChange = (e: any) => {
        // todo validate min max amount
        if (inputAmountRegex.test(e.target.value)) {
            // This is required to support . and , as input. The number seprator inserted is replaced by congi.decimalSign
            const formattedTextAmount = e.target.value.replace('.',config.decimalSign).replace(',',config.decimalSign);

            var formattedAmount = parseFloat(e.target.value.replace(config.decimalSign,'.'));
            formattedAmount = parseInt((formattedAmount * 100).toFixed(0));
            
            var isAmountValid = true;
            if(props.minChargeAmount) {
                const minAmountExceeded = formattedAmount < props.minChargeAmount;
                setIsAmountUnderMinAmount(minAmountExceeded);
                isAmountValid = isAmountValid && !minAmountExceeded;
            }
            if(props.maxChargeAmount) {
                const maxAmountExceeded = formattedAmount > props.maxChargeAmount;
                setIsAmountOverMaxAmount(maxAmountExceeded);
                isAmountValid = isAmountValid && !maxAmountExceeded;
            }
    
            setAmount(formattedTextAmount);

            if(isAmountValid) {
                props.changePayMeOrderAmount(formattedAmount);
            }
            else {
                props.changePayMeOrderAmount(null);
            }
        }
    };

    const getInputWidth = () => {
        if(amount) {
            return `${amount?.toString().length}ch`;
        } else {
            return '3.5ch';
        }
    }

    const formatInsertedAmount = () => {
        if(amount) {
            var formattedAmount = parseFloat(amount.replace(config.decimalSign,'.'));
            setAmount(formattedAmount.toFixed(2).replace('.',config.decimalSign));
        }
    }

    return (
        <div className="formControl">
            <div className={`${styles.amountContainer} ${styles[amountClass]}`}>
                <div>
                    <input autoFocus className={styles.input}
                        id="chargeOrderAmount"
                        type="text"
                        inputMode="decimal" // set inputMode to decimal to show virtual keyboard having decimal separator. See: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode
                        placeholder={`0${config.decimalSign}00'`}
                        value={amount || ''}
                        style={{width: getInputWidth()}}
                        contentEditable={true}
                        onChange={e => onInputChange(e)}
                        onBlur={e => formatInsertedAmount()}
                    />
                    {` `}
                    <CurrencySymbol currency={props.currency}/>
                </div>
            </div>

            <CustomError 
                showError={isAmountUnderMinAmount} 
                content={
                    <React.Fragment>
                        <span className={styles.label}>
                            <Translate text={`orderDetails.minAmount`}/>
                        </span>
                        {` `}
                        <CurrencyConverter 
                            amount={props.minChargeAmount}
                            currency={props.currency}
                        />
                    </React.Fragment>
                }
            />
            <CustomError 
                showError={isAmountOverMaxAmount} 
                content={
                    <React.Fragment>
                        <span className={styles.label}>
                            <Translate text={`orderDetails.maxAmount`}/>
                        </span>
                        {` `}
                        <CurrencyConverter 
                                amount={props.maxChargeAmount}
                                currency={props.currency}
                        />
                    </React.Fragment>
                }
            />
        </div>
    );
};

export default connect(
    (state: RootState) => { 
        return {
        }; 
    },
    {
        changePayMeOrderAmount
    }
)(OrderAmountInput);