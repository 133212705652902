import React from 'react';
import styles from "./styles.module.scss";
import TooltipInfo from './TooltipInfo';
import Translate from '../Translate';

export enum TooltipIconSize {
    Small = 'small',
    Big = 'big',
}

export enum TooltipType {
    Web = 'web',
    Mobile = 'mobile',
}

interface ITooltipProps {
    infoKey: string
    size?: TooltipIconSize,
}

interface ITooltipState {
    showInfo: boolean,
    type: TooltipType,
}

class Tooltip extends React.Component<ITooltipProps, ITooltipState> {

    constructor(props: ITooltipProps) {
        super(props);

        this.state = {
            showInfo: false,
            type: TooltipType.Web,
        }
    }

    componentWillMount() {
        this.onResize();
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    render() {
        const size = this.props.size ? styles[this.props.size] : '';

        return (
            <div className={`${styles.tooltip} ${size}`}>
                <div className={styles.icon}
                     onMouseEnter={this.onMouseAction}
                     onMouseLeave={this.onMouseAction}
                     onClick={this.onClick}/>
                { this.renderInfo() }
            </div>
        )
    }

    private renderInfo() {
        if (!this.state.showInfo) {
            return null;
        }

        return (<TooltipInfo
                    header={<Translate text={`tooltip.${this.props.infoKey}.header`} />}
                    content={<Translate text={`tooltip.${this.props.infoKey}.content`} />}
                    type={this.state.type}/>
                )
    }

    private onMouseAction = () => {
        if (this.state.type !== TooltipType.Web) {
            return;
        }

        this.setState({showInfo: !this.state.showInfo})
    };

    private onClick = () => {
        if (this.state.type !== TooltipType.Mobile) {
            return;
        }

        this.setState({showInfo: !this.state.showInfo})
    };

    private onResize = () => {
        if (window.innerWidth > 992) {
            this.setState({
                type: TooltipType.Web,
                showInfo: false
            });
            return;
        }

        this.setState({type: TooltipType.Mobile});
    }
}

export default Tooltip;