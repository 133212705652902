import {inject, injectable} from 'inversify';
import {ILink2SaveDaoService, Link2SaveDaoService} from './dao/link2Save';
import {LinkStep} from '../model/LinkStep';
import {IErrorHandlerService, ErrorHandlerService} from './errorHandlerService';
import {changeRedirectData} from '../store/reducers/linkDataSlice';
import {changeActiveStep} from '../store/reducers/sagaSlice';
import store from '../store'
import {IConfirmExpect, IConfirmSourceExpect, ICreateExpect, ICreateExpectResp} from '../model/IExpect';
import {PaymentMethodType} from '../model/PaymentMethodType';
import {filter, first, switchMap, tap} from 'rxjs/operators';
import {from, Observable, of} from 'rxjs';
import { IPlan } from '../model/IOrder';

export interface ILink2SaveService {
    //createAndConfirmExpect(stripe: stripeJs.Stripe): Observable<any>;
}

@injectable()
export class Link2SaveService implements ILink2SaveService {

    @inject(Link2SaveDaoService) private link2SaveDaoService: ILink2SaveDaoService;
    @inject(ErrorHandlerService) private errorHandlerService: IErrorHandlerService;

    public createAndConfirmExpect(/*stripe: stripeJs.Stripe*/): Observable<any> {

        const linkSlice = store.getState().linkData;

        if (!linkSlice.order || !linkSlice.order.orderKey) {
            this.errorHandlerService.handleInternalError('[Link2SaveService]: Invalid payload data');
            return of(null);
        }

        if (!linkSlice.paymentMethod || !linkSlice.paymentMethod.id) {
            this.errorHandlerService.handleInternalError('[Link2SaveService]: Invalid payload data');
            return of(null);
        }

        const createExpectPayload: ICreateExpect = {
            orderKey: linkSlice.order.orderKey,
            paymentMethodType: linkSlice.paymentMethodType,
            paymentMethodId: linkSlice.paymentMethod.id,
            savePaymentMethod: linkSlice.savePaymentMethod
        };

        if (linkSlice.customerId) {
            createExpectPayload.customerId = linkSlice.customerId;
        }

        if (linkSlice.validUntil) {
            createExpectPayload.validUntil = linkSlice.validUntil;
        }

        if (linkSlice.contactEmail) {
            createExpectPayload.contactEmail = linkSlice.contactEmail;
        }

        const paymentPlan = linkSlice.order?.plan ? {
            recurrent: linkSlice.order.plan.recurrent,
            schedules: linkSlice.order.plan.schedules?.plans,
            oneTime: linkSlice.order.plan.onetime,
            netAmountFlag: linkSlice.order.netAmountFlag
        } as IPlan : undefined;

        return this.link2SaveDaoService.createExpect(createExpectPayload).pipe(
            first(),
            switchMap((createExpectResp: any) => {
                switch(linkSlice.paymentMethodType) {
                    case PaymentMethodType.CARD:
                        return this.confirmPaymentMethod(null, createExpectResp, paymentPlan);

                    case PaymentMethodType.SEPA_DEBIT:
                        return this.confirmPaymentMethod(null, createExpectResp, paymentPlan, createExpectPayload.paymentMethodId);

                    default:
                        this.errorHandlerService.handleInternalError(`[Link2SaveService]: Invalid PaymentMethodType: ${linkSlice.paymentMethodType}`);
                        return of(null);
                }
            })
        );
    }

    private confirmPaymentMethod(stripeService: any,
                                 createExpectResp: ICreateExpectResp, paymentPlan?: IPlan, paymentMethodId?: string): Observable<any> {

        const optionalParams = paymentMethodId ? { payment_method: paymentMethodId} : undefined;

        return from(stripeService(createExpectResp.client_secret, optionalParams)).pipe(
            first(),
            switchMap((stripeServiceResp: any) => {

                console.log("PaymentPlan: " + JSON.stringify(paymentPlan));
                const confirmExpectPayload: IConfirmSourceExpect = {
                    setupIntentId: createExpectResp.id,
                    stripeStatus: stripeServiceResp.error ? 'expectation_error' : stripeServiceResp.setupIntent.status,
                    paymentPlan
                };

                return this.link2SaveDaoService.confirmExpect(confirmExpectPayload, createExpectResp.expectKey).pipe(
                    first(),
                    tap((confirmExpectResp: any) => {

                        if (stripeServiceResp.error) {
                            this.errorHandlerService.handleStripeError(
                                stripeServiceResp.error,
                                createExpectResp.id,
                                createExpectResp.expectKey,
                            );
                            return;
                        }

                        store.dispatch(changeRedirectData({
                            expectKey: createExpectResp.expectKey,
                            stripeId: createExpectResp.id,
                            state: confirmExpectResp.state,
                        }));

                        store.dispatch(changeActiveStep(LinkStep.Redirect));
                    }),
                )
            })
        )
    }
}


