import React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { RootState } from "../../../store/reducers";
import { orderSelector } from "../../../store/selectors/linkDataSelectors";
import { IOrder, IRecurrentDetails, SchedulePlan } from "../../../model/IOrder";
import CurrencyConverter from "../CurrencyConverter";
import i18n from "../../../i18n";
import { languageSelector } from "../../../store/selectors/sagaSelectors";
import SchedulePlanTooltip from "./SchedulePlanTooltip"
import styles from "./styles.module.scss";

interface IPlanInfoProps {
  order: IOrder | null;
  requiredEndDivider?: boolean;
}

class PlanInfo extends React.Component<IPlanInfoProps> {
  private splitSchedulesInHalf(rawSchedulesList: SchedulePlan[]) {
    // sort schedules by chargeDate (intdate field)
    const sortedRawSchedulesList = rawSchedulesList.sort(
      (a, b) => a.chargeDate - b.chargeDate
    );

    // ~~ create react elements
    const formattedSchedules = [];
    for (const thisSchedule of sortedRawSchedulesList) {
      formattedSchedules.push(
        this.renderSchedulePlan(thisSchedule)
      );
    }

    // split schedules array in half so
    // we can easily populate the two UI columns
    // split code taken from https://stackoverflow.com/a/47777083
    // using ceil so the first column has more elements
    const halfwayThrough = Math.ceil(formattedSchedules.length / 2);

    const arrayFirstHalf = formattedSchedules.slice(0, halfwayThrough);
    const arraySecondHalf = formattedSchedules.slice(
      halfwayThrough,
      formattedSchedules.length
    );

    return {
      left: arrayFirstHalf,
      right: arraySecondHalf,
    };
  }

  private formatOneTime(rawOneTimePlan: SchedulePlan) {
    return (
      <div>
        <span className="planInfoTitle">{i18n.t(`plan.planOneTime`)}</span>
        <div className="planWrapper">
          <div className="mb-27">
            <span className="subtitle">{i18n.t(`orderDetails.amount`)}: </span>
            <span className="planAmount">
              <CurrencyConverter
                amount={rawOneTimePlan.amount}
                currency={this.props.order?.currency}
              />
            </span>
          </div>

          <div>
            <span className="subtitle">{i18n.t(`plan.date`)}: </span>
            <span className="planDate ml-61">
              <Moment format={"DD/MM/YYYY"} parse="YYYYMMDD">
                {rawOneTimePlan.chargeDate}
              </Moment>
            </span>
          </div>
        </div>
      </div>
    );
  }

  private formatSchedules(rawSchedulePlan: SchedulePlan[] | undefined) {
    if (!rawSchedulePlan) {
      return null;
    }

    const schedulesResult = this.splitSchedulesInHalf(rawSchedulePlan);

    // sanity check - for inferring should never happen
    if (!schedulesResult) {
      return null;
    }

    const left = schedulesResult.left;
    const right = schedulesResult.right;

    return (
      <div>
        <span className="planInfoTitle">{i18n.t(`plan.planSchedule`)}</span>
        <div className="planWrapper planCloumnsWrapper">
          <div className="planLeftColumn">{left}</div>
          <div className="planRightColumn">{right}</div>
        </div>
      </div>
    );
  }

  private getFrequencyMessage(recurrentData: IRecurrentDetails) {
    // map proto enum key to the key that rapresents the translation
    // as we are displaying "day" we cannot use "daily" as it has a different meaning
    let frequencyPeriodMapping = "day";

    if (recurrentData.repeatPeriod === "DAILY") {
      frequencyPeriodMapping = "day";
    } else if (recurrentData.repeatPeriod === "MONTHLY") {
      frequencyPeriodMapping = "month";
    } else if (recurrentData.repeatPeriod === "YEARLY") {
      frequencyPeriodMapping = "year";
    }

    let messagePrefix: string = '';

    if (
      typeof recurrentData.repeatEvery === "number" &&
      recurrentData.repeatEvery !== 0
    ) {
      if (recurrentData.repeatEvery > 1) {
        // plural with number in message
        messagePrefix = `${i18n.t("plan.every")} ${recurrentData.repeatEvery} ${i18n.t(
          `plan.period.${frequencyPeriodMapping}`,
          { count: recurrentData.repeatEvery }
        )}`;
        // return `Ogni ${recurrentData.repeatEvery} ${frequencyPeriodMapping}`
      } else {
        // singular with no number in message
        messagePrefix = `${i18n.t("plan.every")} ${i18n.t(
          `plan.period.${frequencyPeriodMapping}`
        )}`;
      }
    }

    if (
      typeof recurrentData.endAfterTimes === "number" &&
      recurrentData.endAfterTimes !== 0
    ) {
      messagePrefix = `${messagePrefix} ${i18n.t("plan.for")} ${recurrentData.endAfterTimes} ${i18n.t(
        "orderDetails.charge",
        { count: recurrentData.endAfterTimes }
      )}`;
      // return per ${recurrentData.endAfterTimes} addebiti`;
    }

    if (
      typeof recurrentData.endOnDate === "number" &&
      recurrentData.endOnDate !== 0
    ) {
      const formattedEndOnDate = (
        <Moment format={"DD/MM/YYYY"} parse="YYYYMMDD">
          {recurrentData.endOnDate}
        </Moment>
      );

      return (
        <span>
          {`${messagePrefix} ${i18n.t("plan.till")} `}
          {formattedEndOnDate}
        </span>
      );
      // return fino al ${formattedEndOnDate}`;
    }


    // at this point no end* was provided, return just prefix as the final message
    return messagePrefix;
  }

  private formatRecurrent() {
    if (!this.props.order?.plan?.recurrent) {
      return null;
    }

    const frequencyMessage = this.getFrequencyMessage(
      this.props.order?.plan?.recurrent
    );

    return (
      <div>
        <span className="planInfoTitle">{i18n.t(`plan.planRecurrent`)}</span>
        <div className="planWrapper">
          <div className="mb-27">
            <span className="subtitle">{i18n.t(`orderDetails.amount`)}: </span>
            <span className="planAmount">
              <CurrencyConverter
                amount={this.props.order?.plan?.recurrent?.amount}
                currency={this.props.order?.currency}
              />
            </span>
          </div>

          <div className="mb-24">
            <span className="subtitle">{i18n.t(`plan.startDate`)}: </span>
            <span className="planDate ml-12">
              <Moment format={"DD/MM/YYYY"} parse="YYYYMMDD">
                {this.props.order?.plan?.recurrent?.startOn}
              </Moment>
            </span>
          </div>

          {
            // if frequencyMessage is set display it
            frequencyMessage ? (
              <div>
                <span className="subtitle">{i18n.t("plan.frequency")}: </span>
                <span className="bold capitalize">{frequencyMessage}</span>
              </div>
            ) : null
          }
        </div>
      </div>
    );
  }

  private renderSchedulePlan(schedulePlan: SchedulePlan) {
    return (
      <div className={styles.schedulePlanInfo}>
        {schedulePlan.originalChargeDate 
          ? <SchedulePlanTooltip className={styles.icon} schedulePlan={schedulePlan}/>
          : <span className={styles.chargeDateSpacing}></span>
        }
        <div>
          <span className="subtitle">
            <Moment format={"DD/MM/YYYY"} parse="YYYYMMDD">
              {schedulePlan.chargeDate}
            </Moment>
          </span>
          <span className="planScheduleAmount">
            <CurrencyConverter
              amount={schedulePlan.amount}
              currency={this.props.order?.currency}
            />
          </span>
        </div>
      </div>
    );
  }

  /**
   * recognize type of plan based on order.plan structure,
   * with additional workaround for proto on empty objects {}
   */
  getTypeOfPlan() {
    const planObject = this.props.order?.plan;

    if (typeof planObject?.schedules?.plans !== "undefined") {
      return "schedules";
    }

    if (typeof planObject?.recurrent?.amount !== "undefined") {
      return "recurrent";
    }

    if (typeof planObject?.onetime?.amount !== "undefined") {
      return "onetime";
    }
  }

  renderEndDivider() {
    if (this.props.requiredEndDivider) {
      return (
        <div className="divider"></div>
      );
    }
  }

  render() {
    const typeOfPlan = this.getTypeOfPlan();

    if (!this.props.order || !this.props.order.plan || !typeOfPlan) {
      return null;
    }

    let planContent: JSX.Element | null = null;

    if (typeOfPlan === "onetime") {
      const oneTime = this.props.order.plan.onetime;
      // for infering
      if (!oneTime) {
        return null;
      }
      planContent = this.formatOneTime(oneTime);
    } else if (typeOfPlan === "schedules") {
      planContent = this.formatSchedules(
        this.props.order.plan.schedules?.plans
      );
    } else if (typeOfPlan === "recurrent") {
      planContent = this.formatRecurrent();
    }

    // sanity check - should never happen
    if (!planContent) {
      return null;
    }

    return (
      <div className="planInfo">
        <div className="divider"></div>

        <div className="planContent">
            {planContent}
        </div>

        { this.renderEndDivider() }
      </div>
    );
  }
}

export default connect((state: RootState) => {
  return {
    order: orderSelector(state),
    activeLanguage: languageSelector(state)
  };
}, {})(PlanInfo);
