import React from 'react';
import {useTranslation} from 'react-i18next';

export const BrowserNotCompatibleError = () => {
    const { t } = useTranslation();

    return (
        <div className="content errorContainer">
            <div className="mb-36 errorMessage">
                { t('browserNotSupported') }
            </div>
        </div>
    );
};
