import React, {FC} from 'react';
import Translate from '../../Common/Translate';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {orderSelector} from '../../../store/selectors/linkDataSelectors';
import {IOrder} from '../../../model/IOrder';
import {changeActiveStep, changeFlow, FlowType} from '../../../store/reducers/sagaSlice';
import {changeSavePaymentMethod} from '../../../store/reducers/linkDataSlice';
import OrderDetails from '../../Common/OrderDetails';
import DateInput from '../../Common/DatelInput';
import ExpirationInfo from '../../Common/ExpirationInfo';
import PlanInfo from '../../Common/PlanInfo';
import { languageSelector } from '../../../store/selectors/sagaSelectors';
import CancelButton from '../../Common/CancelButton';
import PaymentMethodOptionList from "../../Common/PaymentMethodOptionList";
import OrderEmailNoteBox from '../../Common/OrderEmailNoteBox';

interface IPaymentDetailsProps {
    order: IOrder | null;
    changeActiveStep: typeof changeActiveStep;
    changeSavePaymentMethod: typeof changeSavePaymentMethod;
    changeFlow: typeof changeFlow;
}

const PaymentDetails:FC<IPaymentDetailsProps> = (props: IPaymentDetailsProps) => {

    props.changeFlow(FlowType.PM_PROVIDED_BY_USER_AND_NOT_SAVED);

    if (!props.order || !props.order.sourceTypes || !Array.isArray(props.order.sourceTypes)) {
        return null;
    }

    const hasAPlan = props.order.plan;

    const renderCancelButton = () => {
        if(props.order?.cancelUrl){
            return <CancelButton />;
        }
    };

    return (
        <React.Fragment>
            <h1 className="header title"><Translate text={`step.paymentMethodSave.title`}/></h1>
            <OrderDetails/>

            {
                // if order has a plan display it otherwise display the validUntil date form input
                hasAPlan ? <PlanInfo requiredEndDivider={!props.order?.contactRequest && !props.order?.confirmationPageNote} /> : <DateInput/>
            }

            <OrderEmailNoteBox />

            <div className="accordionContainer" id="pmAccordion">
                <PaymentMethodOptionList />
                {renderCancelButton()}
                <ExpirationInfo />

            </div>

        </React.Fragment>
    );
};

export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state),
            activeLanguage: languageSelector(state),
        };
    },
    {
        changeSavePaymentMethod,
        changeActiveStep,
        changeFlow
    }
)(PaymentDetails);
