import React, {FC} from 'react';
import Translate from '../Translate';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import { languageSelector } from '../../../store/selectors/sagaSelectors';
import { LanguageLocale } from '../../../constants/locales';
import { WindowWithCustomAppConfig } from '../../../core';

interface ITspayInformationalPdfProps {
    language?: LanguageLocale
    orderFee: number
}

const TspayInformationalPdf:FC<ITspayInformationalPdfProps> = (props: ITspayInformationalPdfProps) => {
    
    const APP_CONFIG = (window as WindowWithCustomAppConfig).$APP_CONFIG;
    const urlInformationalPdf = APP_CONFIG?.informationalPdfUrl || '#';
    console.log("urlInformationalPdf " + urlInformationalPdf);
    console.log("termsOfServiceUrl " + APP_CONFIG?.termsOfServiceUrl);

    const formatNumberForLang = (value: number) => {
        const intlFormatter = Intl.NumberFormat(props.language, {minimumFractionDigits: 2});

        return intlFormatter.format(value);
    }
    

        return (
            <div className="Tspay-Informational">
                <span className="text-style-1"><Translate text={'step.netAmountChargePdf.info'}/> {formatNumberForLang(props.orderFee)}€&nbsp;</span>
                <a href={urlInformationalPdf} target="_blank"><Translate text={'step.netAmountChargePdf.url'}/></a>
            </div>
        
        );
}


export default connect(
    (state: RootState) => {
        return {
            language: languageSelector(state),
        };
    },
    {

    }
)(TspayInformationalPdf);