import React from 'react';
import {useTranslation} from 'react-i18next';

export const InvalidUrlError = () => {
    const { t } = useTranslation();

    return (
        <div className="content errorContainer">
            <div className="mb-36 errorMessage">
                { t('invalidUrl') }
            </div>
        </div>
    );
};
