import {IJson} from '../../core';
import {AbstractRestGetService} from './base';
import {ICreateExpect, IConfirmExpect} from '../../model/IExpect';
import {Observable} from 'rxjs';
import {inject} from 'inversify';
import {ErrorHandlerService, IErrorHandlerService} from '../errorHandlerService';

export interface ILink2SaveDaoService {
    retrieveOrder(orderKey: string): Observable<any>;
    createExpect(data: ICreateExpect): Observable<any>;
    confirmExpect(data: IConfirmExpect, expectKey: string): Observable<any>;
}

export class Link2SaveDaoService extends AbstractRestGetService<IJson> implements ILink2SaveDaoService {
    rootUrl: string = '/link2Save/';

    @inject(ErrorHandlerService) errorHandlerService: IErrorHandlerService;

    protected get errorHandler(): any {
        return this.errorHandlerService;
    }

    retrieveOrder(orderKey: string): Observable<any> {
        return this.get(orderKey);
    }

    createExpect(data: ICreateExpect): Observable<any> {
        return this.post('expect', data);
    }

    confirmExpect(data: IConfirmExpect, expectKey: string): Observable<any> {
        return this.put(`expect/${expectKey}`, data);
    }
}
