import React, {useContext} from 'react';
import { Container, interfaces } from 'inversify';
import { Store, Action, AnyAction } from 'redux';
import { Provider } from 'react-redux';
import {RootState} from '../store/reducers';

/**
 * Context use by InjectionReduxProvider for inversify
 */
const InversifyContext = React.createContext<{ container: Container | null }>({ container: null });

interface InjectionReduxProps<A extends Action = AnyAction> {
    store: Store<RootState, A>;
    container: Container;
};


/**
 * A provider that wrap Redux Provider and create a inversify provider
 * 
 * @param props 
 */
export const InjectionReduxProvider: React.FC<InjectionReduxProps> = (props) => {
    return (
        <Provider store={props.store}>
            <InversifyContext.Provider value={{ container: props.container }}>
                {props.children}
            </InversifyContext.Provider>
        </Provider>
    );
};


/**
 * A injection hook
 * 
 * @param identifier 
 */
export function useInjection<T>(identifier: interfaces.ServiceIdentifier<T>) {
    const { container } = useContext(InversifyContext);
    if (!container) { throw new Error(); }
    return container.get<T>(identifier);
};