import React, {FC} from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {orderSelector, redirectDataSelector} from '../../../store/selectors/linkDataSelectors';
import {cleanState} from '../../../store/reducers/linkDataSlice';
import {IOrder} from '../../../model/IOrder';
import {useInjection} from '../../../ioc';
import {HashDaoService} from '../../../service/dao';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {activeLinkNameSelector, languageSelector} from '../../../store/selectors/sagaSelectors';
import {LinkName} from '../../../model/LinkName';
import {Redirect as RouterRedirect} from 'react-router-dom';
import {first, tap} from 'rxjs/operators';
import { LanguageLocale } from '../../../constants/locales';
import Translate from '../../Common/Translate';

export interface IRedirectProps extends RouteComponentProps {
    order: IOrder | null;
    redirectData: any;
    activeLinkName: LinkName | null;
    cleanState: typeof cleanState;
    activeLanguage: LanguageLocale;
}

const Redirect:FC<IRedirectProps> = (props: IRedirectProps) => {
    const hashDaoService = useInjection(HashDaoService);
    const order = props.order as IOrder;
    const now = new Date();
    const timestamp = now.toISOString();

    if (!props.redirectData) {
        console.log('No redirect data, show error');
        return <RouterRedirect to={'/'} />;
    }

    // no callbackUrl redirect to thank you
    if (!order.callbackUrl) {
        props.history.push('/thank-you');
        return null;
    }

    const getHashPayload: any = {
        orderKey: order.orderKey,
        state: props.redirectData.state,
        expectKey: props.redirectData.expectKey,
        timestamp,
    };

    // add selectedLangLocale only if present
    if (typeof props.activeLanguage === 'string') {
        getHashPayload.selectedLangLocale = props.activeLanguage;
    }

    hashDaoService.getHash(getHashPayload).pipe(
        first(),
        tap((resp: any) => {
            // no callbackUrl
            if (!order.callbackUrl) {
                props.history.push('/thank-you');
                return;
            }

            const token = resp.token;
            // add token as 'data' to callbackUrl
            window.location.href = `${order.callbackUrl}${order.callbackUrl.indexOf("?") === -1 ? "?" : "&" }data=${token}`;


            // OLD CODE BELOW
            /*
            const paramsObj: any = {
                timestamp,
                orderKey: order.orderKey,
                hash: resp.hash,
                state: props.redirectData.state,
            };

            if (props.redirectData.expectKey) {
                switch(props.activeLinkName) {
                    case LinkName.Link2Save:
                        paramsObj.sourceKey = props.redirectData.expectKey;
                        break;
                    case LinkName.Link2Pay:
                        paramsObj.chargeKey = props.redirectData.expectKey;
                        break;
                }
            }

            let urlParams = Object.entries(paramsObj).map(e => e.join('=')).join('&');

            // no callbackUrl
            if (!order.callbackUrl) {
                props.history.push('/thank-you');
                return;
            }

            // compose redirect url
            window.location.href = `${order.callbackUrl}${order.callbackUrl.indexOf("?") === -1 ? "?" : "&" }${urlParams}`;
            */
        })
    ).subscribe();


    return (
        <React.Fragment>
            <Translate text={"loading"} />
        </React.Fragment>
    );
};


export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state),
            redirectData: redirectDataSelector(state),
            activeLinkName: activeLinkNameSelector(state),
            activeLanguage: languageSelector(state),
        };
    },{
        cleanState
    }
)(withRouter(Redirect));
