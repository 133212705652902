import { createSlice } from "redux-starter-kit";
import {LanguageLocale} from '../../constants/locales';
import {LinkStep} from '../../model/LinkStep';
import {LinkName} from '../../model/LinkName';
import {IError} from '../../model/IError';
import {DEFAULT_LANG_LOCALE} from '../../constants/locales';

export enum FlowType {
    PM_PROVIDED_BY_USER_AND_NOT_SAVED = 'PM_PROVIDED_BY_USER_AND_NOT_SAVED',
    PM_PROVIDED_BY_USER_AND_SAVED = 'PM_PROVIDED_BY_USER_AND_SAVED',
    PM_TAKEN_FROM_LIST = 'PM_TAKEN_FROM_LIST',
}

export interface ISagaState {
    activeStep: LinkStep | null;
    activeLinkName: LinkName | null;
    language: LanguageLocale,
    flow: FlowType;
    error: any;
}

export interface IChangeActiveStep {
    readonly payload: {
        activeStep: any;
    };
}

export interface IChangeActiveLinkName {
    readonly payload: {
        activeLinkName: LinkName;
    };
}

export interface IChangeLanguage {
    readonly payload: {
        language: any;
    };
}

export interface IChangeFlow {
    readonly payload: {
        flow: FlowType;
    };
}

export interface IChangeError {
    readonly payload: {
        error: IError | null;
    };
}

const initialState: ISagaState = {
    activeStep: null,
    activeLinkName: null,
    language: DEFAULT_LANG_LOCALE,
    flow: FlowType.PM_PROVIDED_BY_USER_AND_NOT_SAVED,
    error: null,
};

const sagaSlice = createSlice({
    slice: "saga",
    initialState: initialState,
    reducers: {
        changeActiveStep: {
            reducer: (state: ISagaState, action: IChangeActiveStep) => {
                return {
                    activeStep: action.payload.activeStep,
                    activeLinkName: state.activeLinkName,
                    language: state.language,
                    flow: state.flow,
                    error: state.error,
                };
            },
            prepare(activeStep: any) {
                return {
                    payload: { activeStep: activeStep }
                };
            }
        },
        changeActiveLinkName: {
            reducer: (state: ISagaState, action: IChangeActiveLinkName) => {
                return {
                    activeStep: state.activeStep,
                    activeLinkName: action.payload.activeLinkName,
                    language: state.language,
                    flow: state.flow,
                    error: state.error,
                };
            },
            prepare(activeLinkName: LinkName) {
                return {
                    payload: { activeLinkName: activeLinkName }
                };
            }
        },
        changeLanguage: {
            reducer: (state: ISagaState, action: IChangeLanguage) => {
                return {
                    activeStep: state.activeStep,
                    activeLinkName: state.activeLinkName,
                    language: action.payload.language,
                    flow: state.flow,
                    error: state.error,
                };
            },
            prepare(language: LanguageLocale) {
                return {
                    payload: { language: language }
                };
            }
        },
        changeFlow: {
            reducer: (state: ISagaState, action: IChangeFlow) => {
                return {
                    activeStep: state.activeStep,
                    activeLinkName: state.activeLinkName,
                    language: state.language,
                    flow: action.payload.flow,
                    error: state.error,
                };
            },
            prepare(flow: FlowType) {
                return {
                    payload: { flow: flow }
                };
            }
        },
        changeError: {
            reducer: (state: ISagaState, action: IChangeError) => {
                return {
                    activeStep: state.activeStep,
                    activeLinkName: state.activeLinkName,
                    language: state.language,
                    flow: state.flow,
                    error: action.payload.error,
                };
            },
            prepare(error: IError) {
                return {
                    payload: { error: error }
                };
            }
        },
    }
});
export const {
    changeActiveStep,
    changeActiveLinkName,
    changeLanguage,
    changeFlow,
    changeError,
} = sagaSlice.actions;
export default sagaSlice.reducer;