import errorMapping from './pay-webapp-error-mapping.json';
import { render as mustacheRender } from "mustache";
import { DEFAULT_LOCALE, LanguageLocale } from '../constants/locales';
import { PaymentMethodType } from '../model/PaymentMethodType';
import { IOrder } from '../model/IOrder';
import { ITspaySettings } from '../model/ITspaySettings';
import store from '../store';
import { addQueryParams } from '../utils/queryStringUtils';

/**
 * A Basic interface used to define a JSON object type.  The key
 * difference to `Any` type is that the key must be a string
 */
export interface IJson {
    [key: string]: any;
}

interface IConfig {
    dataUrl: string;
    stripe: {
        apiVersion: string;
    }
}

const defaultConfig = {
    decimal: 2,
    decimalSign: ',',
    showCurrency: true
};

const englishConfig = {
    decimalSign: '.',
}

export interface IPriceConverterConfig {
    decimal: number;
    decimalSign: string;
    showCurrency: boolean;
}

// $APP_CONFIG loaded in index.html
export type WindowWithCustomAppConfig = Window & {
    $APP_CONFIG?: {
        /**
         * if true will display test/iban cards
         */
        displayTestCard?: boolean,
        /**
         * home url webapp page
         */
        homeWebappUrl?: string,
        /**
         * stp url webapp page
         */
        stpWebappUrl?: string,
        /**
         * pp url webapp page
         */
        ppWebappUrl?: string,
        /**
         * cko url webapp page
         */
        ckoWebappUrl?: string,
        /**
         * allow to change dashboard url displayed in /api-key-activated page
         */
        dashboardUrl?: string,
        /**
         * gainsight analytics Id
         */
        gainsightId?: string,

        /**
         * privacy policy url
         */
        privacyPolicyUrl: string,

        /**
         * terms of service url
         */
        termsOfServiceUrl: string,

        /**
         * informational pdf url
         */
        informationalPdfUrl: string
    },
    /**
    * function for theme
    */
    applyTheme?: (...args: any[])=>void
};

export function Config(): IConfig {
    return {
        dataUrl: '/d/web',
        stripe: {
            apiVersion: '2020-08-27'
        }
    }
}

/**
 * from language locale get a clean generic locale
 * e.g. from en-US returns en
 */
export function getGenericLocale(langLocale: LanguageLocale | string) {
    let cleanLocale = langLocale.split('-')?.[0]?.toLowerCase() || DEFAULT_LOCALE;
    if (
        cleanLocale !== 'en' &&
        cleanLocale !== 'it'
    ) {
        cleanLocale = DEFAULT_LOCALE;
    }

    return cleanLocale as 'en' | 'it';
}

/**
 * by providing an errorKey and errorMessagePayload returned translated
 * error based on langLocale used
 */
export function getTranslatedError(
    languageLocale: (LanguageLocale | string),
    errorKey: string,
    payload: IJson = {}
) {
    const availableErrors = errorMapping;

    // check if we have a matching of errorKey
    if (
        !(errorKey in availableErrors)
    ) {
        return undefined;
    }

    // ~~~ matching errorKey found, going to translate the message
    const matchError = availableErrors[errorKey as keyof typeof availableErrors];

    console.log('matching error found', matchError);

    // get only the initial generic lang prefix (e.g. en / it)
    const locale = getGenericLocale(languageLocale);

    // add extra checks for infering/ts
    const templateMessage = locale in matchError.message ? matchError.message[locale as 'en'] : matchError.message.en;
    const description = locale in matchError.description ? matchError.description[locale as 'en'] : matchError.description.en;

    const renderedMessage = mustacheRender(templateMessage, payload);

    return {
        raw: matchError,
        message: renderedMessage,
        description: description ? description : undefined
    }
}

export function getPriceConverterConfigFromLanguage(selectedLang?: LanguageLocale, inputConfig?: IPriceConverterConfig): IPriceConverterConfig {
    const deepCopyConfig = JSON.parse(JSON.stringify(defaultConfig));
    let config: IPriceConverterConfig = deepCopyConfig;

    if (!inputConfig) {
        config = deepCopyConfig;
    } else {
        if(!inputConfig.decimal) {
            config['decimal'] = deepCopyConfig['decimal'];
        }
        if (!inputConfig.decimalSign) {
            config['decimalSign'] = deepCopyConfig['decimalSign'];
        }
        if(!inputConfig.decimal) {
            config['showCurrency'] = deepCopyConfig['showCurrency'];
        }
    }

    // if language used is english must use . as decimal sign
    // ref https://gitlab.com/tspay/pay-webapp/-/issues/78
    if (selectedLang === LanguageLocale.EN) {
        config['decimalSign'] = englishConfig.decimalSign;
    }

    return config;
}

/**
 *  if a paymethod is included in both acquirer, cko has the priority
 */ 
export function getRedirectFromAcquirerAvailableForPayMethod(order: IOrder, payMethod: PaymentMethodType, requiredAcquirer?: string): string {
    const stpAcquirerConfig = order.acquire?.stp;
    const ppAcquirerConfig = order.acquire?.pp;
    const ckoAcquirerConfig = order.acquire?.cko;

    let acquirerRedirect = null;
    
    // When requiredAcquirer is set force redirect to that specific acquirer
    if(
        requiredAcquirer === 'stp' ||
        (stpAcquirerConfig && stpAcquirerConfig.payMethod?.includes(payMethod))
    ) {
        const APP_CONFIG = (window as WindowWithCustomAppConfig).$APP_CONFIG;
        const stpWebappUrl = APP_CONFIG?.stpWebappUrl;
        acquirerRedirect = `${stpWebappUrl}/stp`;
    }
    
    if(
        requiredAcquirer === 'pp' ||
        (ppAcquirerConfig && ppAcquirerConfig.payMethod?.includes(payMethod))
    ) {
        const APP_CONFIG = (window as WindowWithCustomAppConfig).$APP_CONFIG;
        const ppWebappUrl = APP_CONFIG?.ppWebappUrl;
        acquirerRedirect = `${ppWebappUrl}/pp`;
    }

    if(
        requiredAcquirer === 'cko' ||
        (ckoAcquirerConfig && ckoAcquirerConfig.payMethod?.includes(payMethod))
    ) {
        const APP_CONFIG = (window as WindowWithCustomAppConfig).$APP_CONFIG;
        const ckoWebappUrl = APP_CONFIG?.ckoWebappUrl;
        acquirerRedirect = `${ckoWebappUrl}/cko`;
    }

    if(!acquirerRedirect) {
        throw new Error("Invalid paymethod acquirer");
    }

    return acquirerRedirect;
}

export function navigateToAcquirerDedicateWebApp(order:IOrder, payMethodType: PaymentMethodType, requiredAcquirer?: string) {
    const acquirerRedirect = getRedirectFromAcquirerAvailableForPayMethod(order, payMethodType, requiredAcquirer);
    
    //TODO: Remove this, to use just window.location.pathname, when PayMeC is deprecated
    var formatterPathName = window.location.pathname
    if(order.type === 'payme') {
        formatterPathName = `/payMe/${order.orderKey}`;
    }

    let hrefUrl = `${acquirerRedirect}/${payMethodType}${formatterPathName}`

    const linkSlice = store.getState().linkData;
    const sageSlice = store.getState().saga;
    const lang = sageSlice.language;
    const contactEmail = linkSlice.contactEmail;
    const validUntil = linkSlice.validUntil;
    const payMeOrderAmount = linkSlice.payMeOrderAmount;
    const chargePaymentRef = linkSlice.chargePaymentRef;

    let tspaySetting: ITspaySettings | undefined = undefined;
    if(lang || contactEmail || validUntil || payMeOrderAmount || chargePaymentRef) {
        tspaySetting = {};
        if(lang) {
            tspaySetting.lang = lang;
        }
        if(contactEmail) {
            tspaySetting.e = contactEmail;
        }
        if(validUntil) {
            tspaySetting.vu = validUntil;
        }
        if(payMeOrderAmount) {
            tspaySetting.poa = payMeOrderAmount;
        }
        if(chargePaymentRef) {
            tspaySetting.cpr = chargePaymentRef;
        }
    }

    if(tspaySetting) {
        hrefUrl = addQueryParams(hrefUrl, tspaySetting);
    }

    window.location.href = hrefUrl;
}

export function orderRequiredExtraData(order: IOrder): boolean {
    if(
        order.confirmationPageNote || order.contactRequest || 
        (order.type === 'link2save' && order.plan) ||
        (order.type === 'payme')
    ) {
        return true;
    } else {
        return false;
    }
        
}