import { RootState } from "../reducers";
import { createSelector } from "reselect";
import { ISagaState } from "../reducers/sagaSlice";

export const selectLink = (state: RootState): ISagaState => {
  return state.saga;
};

export const activeStepSelector = createSelector(
    [selectLink],
    (state: ISagaState) => state.activeStep
);

export const activeLinkNameSelector = createSelector(
    [selectLink],
    (state: ISagaState) => state.activeLinkName
);

export const languageSelector = createSelector(
    [selectLink],
    (state: ISagaState) => state.language
);

export const flowSelector = createSelector(
    [selectLink],
    (state: ISagaState) => state.flow
);

export const errorSelector = createSelector(
    [selectLink],
    (state: ISagaState) => state.error
);
