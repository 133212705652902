import React, {FC} from 'react';
import {ILogoOrderTemplateResponse, ILogoTemplateResponse, IOrder, ISimpleOrderTemplateResponse} from '../../../model/IOrder';
import CurrencyConverter from '../CurrencyConverter';
import styles from "./styles.module.scss";
import Translate from '../Translate/index';
import {useInjection} from '../../../ioc';
import {ErrorHandlerService} from '../../../service';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {chargePaymentRefSelector, orderSelector, payMeOrderAmountSelector} from '../../../store/selectors/linkDataSelectors';
import { languageSelector } from '../../../store/selectors/sagaSelectors';
import { LanguageLocale } from '../../../constants/locales';
import OrderAmountInput from '../OrderAmountInput';
import ChargePaymentRefInput from '../ChargePaymentRefInput';


export interface IOrderDetailsProps {
    order: IOrder | null;
    config?: any;
    language?: LanguageLocale,
    editableAmount?: boolean,
    payMeOrderAmount?: number | null;
    chargePaymentRef?: string | null;
}

const OrderDetails:FC<IOrderDetailsProps> = (props: IOrderDetailsProps) => {
    const errorHandlerService = useInjection(ErrorHandlerService);

    if (!props.order || !props.order.template || !props.order.template.type) {
        return null;
    }

    let template: ISimpleOrderTemplateResponse | ILogoTemplateResponse | null = null;
    switch(props.order.template.type) {
        case 'simple':
            template = props.order.template.simple;
            break;
        case 'logo':
            template = props.order.template.logo;
            break;
        default:
            errorHandlerService.handleInternalError(`[OrderDetails]: Invalid template type: ${props.order.template['type']}`);
            return null;
    }

    const titleClass = props.config && props.config.title ? props.config.title.class : '';
    const subtitleClass = props.config && props.config.subtitle ? props.config.subtitle.class : '';
    const headerClass = props.config && props.config.header ? props.config.header.class : '';
    const amountClass = props.config && props.config.amount ? props.config.amount.class : '';
    const maxAmountClass = props.config && props.config.maxAmount ? props.config.maxAmount.class : '';
    const positionSubTitleClass = props.config && props.config.positionSubTitle ? styles[props.config.positionSubTitle.class] : '';
    const maxAmountTitleClass = props.config && props.config.maxAmountTitle ? props.config.maxAmountTitle.class : '';
    const underOrderTitlesClass = props.config && props.config.underOrderTitlesSeparator ? styles.smallUnder : '';
    const underAmountClass = props.config && props.config.underAmountSeparator ? styles.under : '';

    const renderMaxAmount = () => {
        if (!props.order || !props.order.maxAmount) {
            return null;
        }

        return (
            <React.Fragment>
                <div className={`${styles.label} ${styles[maxAmountTitleClass]}`}>
                    <Translate text={`orderDetails.maxAmount`}/>
                </div>
                <div className={`${styles.amountContainer} ${styles[maxAmountClass]}`}>
                    <CurrencyConverter amount={props.order.maxAmount} currency={props.order.currency}/>
                </div>
            </React.Fragment>
        )
    };

    // render logo url if present
    const renderLogoUrl = () => {
        if (!template || !('logoUrl' in template)) {
            return null;
        }

        const templateLogo: ILogoOrderTemplateResponse = template;

        return (
            <img className={styles.logoUrl} src={templateLogo?.logoUrl} alt="logo"/>
        );
    }

    const renderAmount = () => {
        if (!props.order) {
            return null;
        }

        const amount = props.order.type === 'payme' ? props.payMeOrderAmount : props.order.amount;
        if(!amount) {
            return null;
        }

        return (
            <React.Fragment>
                <div className={`${styles.amountContainer} ${styles[amountClass]}`}>
                    <CurrencyConverter
                        amount={amount}
                        currency={props.order.currency}
                        language={props.language}
                    />
                </div>
            </React.Fragment>
        )
    };

    const renderEditableAmount = () => {
        if (!props.order || props.order.amount) {
            return null;
        }

        var minChargeAmount = props.order.minChargeAmount;
        var maxChargeAmount = props.order.maxChargeAmount;

        return (
            <React.Fragment>
                <div className="formControl">
                    <OrderAmountInput
                        config={{amount: {class: 'center'}}}
                        minChargeAmount={minChargeAmount}
                        maxChargeAmount={maxChargeAmount}
                        currency={props.order.currency}
                        language={props.language}
                    />
                </div>
            </React.Fragment>
        )
    };

    const renderOrderDetailAmount = () => {
        if(props.editableAmount) {
            return renderEditableAmount();
        } else {
            return renderAmount();
        }
    }

    const renderChargePaymentRef = () => {
        if(props.editableAmount) {
            return (
                <div className={`${styles.chargePaymentRefContainer}`}>
                    <ChargePaymentRefInput />
                </div>
            );
        } else {
            if(props.chargePaymentRef)
                return <h2 className={`${styles.subtitle} ${styles.chargePaymentRef} wordwrap`}>{ props.chargePaymentRef }</h2>;
        }

    }

    return (
        <div className={styles.dataContainer}>
            <div className={`${styles.title} ${styles.under} ${positionSubTitleClass}`}> <Translate text={`orderDetails.companyNamePrefix`}/>{ props.order.companyName }</div>
            <div className={`${styles.line}`}></div>
            { renderLogoUrl() }

            <div className={underOrderTitlesClass}>
                <h1 className={`${styles.title} ${styles[titleClass]} wordwrap`}>{ template.title }</h1>
                <h2 className={`${styles.subtitle} ${styles[subtitleClass]} wordwrap`}>{ template.desc }</h2>
                <h2 className={`${styles.header} ${styles[headerClass]} wordwrap`}>{ template.paymentRef }</h2>
            </div>

            <div className={underAmountClass}>
                { renderOrderDetailAmount() }
                { renderMaxAmount() }
                { renderChargePaymentRef() }
            </div>
        </div>
    );
};

export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state),
            language: languageSelector(state),
            payMeOrderAmount: payMeOrderAmountSelector(state),
            chargePaymentRef: chargePaymentRefSelector(state)
        };
    },
    {}
)(OrderDetails);