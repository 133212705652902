import {IJson} from '../../core';
import { AbstractRestGetService } from './base';
import {Observable} from 'rxjs';
import {inject} from 'inversify';
import {ErrorHandlerService, IErrorHandlerService} from '../errorHandlerService';

export interface IHashDaoService {
    getHash(data: any): Observable<any>;
}

export class HashDaoService extends AbstractRestGetService<IJson> implements IHashDaoService {
    rootUrl: string = '/hash';

    @inject(ErrorHandlerService) private errorHandlerService: IErrorHandlerService;

    protected get errorHandler(): any {
        return this.errorHandlerService;
    }

    getHash(data: any): Observable<any> {
        return this.put('/', data);
    }
}
