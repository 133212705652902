import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {it} from "./assets/i18n/it";
import {en} from "./assets/i18n/en";
import { LanguageLocale } from "./constants/locales";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  [LanguageLocale.EN]: {
    translation: en
  },
  [LanguageLocale.IT]: {
    translation: it
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    lng: LanguageLocale.IT,

    keySeparator: ".", // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
