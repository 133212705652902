import {IJson} from '../../core';
import {AbstractRestGetService} from './base';
import {Observable} from 'rxjs';
import {IConfirmExpect, ICreateExpect, ICreateExpectForPayMe} from '../../model/IExpect';
import {inject} from 'inversify';
import {ErrorHandlerService, IErrorHandlerService} from '../errorHandlerService';

export interface IPayMeDaoService {
    retrieveOrder(orderKey: string): Observable<any>;
    createPaymentIntent(data: ICreateExpect): Observable<any>;
    confirmPaymentIntent(data: IConfirmExpect, chargeKey: string): Observable<any>;
}

export class PayMeDaoService extends AbstractRestGetService<IJson> implements IPayMeDaoService {
    rootUrl: string = '/payme/';

    @inject(ErrorHandlerService) errorHandlerService: IErrorHandlerService;

    protected get errorHandler(): any {
        return this.errorHandlerService;
    }

    retrieveOrder(orderKey: string): Observable<any> {
        return this.get(orderKey);
    }

    //TODO: remove stp here!!
    createPaymentIntent(data: ICreateExpectForPayMe): Observable<any> {
        return this.post(`stp/${data.orderKey}/intent`, data);
    }

    confirmPaymentIntent(data: any, chargeKey: string): Observable<any> {
        return this.put(`stp/${data.orderKey}/intent/${chargeKey}`, data);
    }
}
