import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {orderSelector, validUntilSelector} from '../../../store/selectors/linkDataSelectors';
import {changeValidUntil} from '../../../store/reducers/linkDataSlice';
import {EnumCustomColor, IOrder} from '../../../model/IOrder';
import Tooltip from '../Tooltip';
import i18n from '../../../i18n';
import { languageSelector } from '../../../store/selectors/sagaSelectors';

interface IExternalDateInputProps {
}

interface IConnectedDateInputProps {
    order: IOrder | null;
    validUntil: string | null;
    changeValidUntil: typeof changeValidUntil;
}

interface IDateInputProps extends IExternalDateInputProps, IConnectedDateInputProps {}

export interface IDateInputState {
    validUntil: string | null;
    isValid: boolean;
    isTouched: boolean;
}

class DateInput extends React.Component<IDateInputProps, IDateInputState> {

    private onInputChange = (e: any) => {
        if (!e.target) {
            return;
        }

        this.setState({
            validUntil: e.target.value
        });

        this.props.changeValidUntil(e.target.value);

    };

    private onInputBlur = () => {
        if ( !this.state || !this.state.isTouched) {
            this.setState({isTouched: true});
        }
    };

    componentDidMount() {
        if (!this.props.validUntil) {
            return;
        }

        this.setState({validUntil: this.props.validUntil})
    }

    render() {
        if (this.props.order?.contextId) {
            return null;
        }
        const value = this.state && this.state.validUntil ? this.state.validUntil : '';

        return (
            <div className="formControl horizontal">
                <label className="capitalize">{ i18n.t('plan.till') }</label>
                <div className={this._getCustomDateInputClass()}>
                    <input 
                           id="validUntil"
                           type="date"
                           onChange={this.onInputChange}
                           min={this.getMinDate()}
                           onBlur={this.onInputBlur}
                           value={value}
                           placeholder={i18n.t('step.paymentDetails.enterDate')}/>
                </div>
                <Tooltip infoKey={'dateInput'}/>
            </div>
        );
    }

    private getMinDate(): string {
        const now = new Date(),
            year = now.getFullYear();
        let day: any = now.getDate(),
            month: any = now.getMonth() + 1;

        if(month < 10) month = '0' + month.toString();
        if(day < 10) day = '0' + day.toString();

        return year + '-' + month + '-' + day;
    }

    private _getCustomDateInputClass(): string {
        // base class
        let customDateInputClassName = 'customDateInput';
        
        // add white or black based on order's settings
        if (this.props.order?.customTheme?.calendarIcon === EnumCustomColor.WHITE) {
            customDateInputClassName += '--white';
        } else {
            customDateInputClassName += '--black';
        }

        // check if browser is chrome alike (webkit engine) and add -webkit suffix
        // ref https://stackoverflow.com/a/21756811
        if (navigator.userAgent.indexOf('AppleWebKit') !== -1) {
            customDateInputClassName += '-webkit';
        }

        return customDateInputClassName;
    }
}

export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state),
            validUntil: validUntilSelector(state),
            language: languageSelector(state),
        };
    },
    {
        changeValidUntil
    }
)(DateInput);