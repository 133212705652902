import {IJson} from '../../core';
import { AbstractRestGetService } from './base';
import {ITargetRequest, IValidationRequest} from '../../model/IVerification';
import {Observable} from 'rxjs';
import {inject} from 'inversify';
import {ErrorHandlerService, IErrorHandlerService} from '../errorHandlerService';

export interface IAuthenticationDaoService {
    // VerificationRequest(data: ITargetRequest): Observable<any>;
    // VerificationValidation(data: any): Observable<any>;
    TOTPRequest(data: ITargetRequest): Observable<any>;
    TOTPValidation(data: IValidationRequest): Observable<any>;
}

export class AuthenticationDaoService extends AbstractRestGetService<IJson> implements IAuthenticationDaoService {
    rootUrl: string = '/authentication';

    @inject(ErrorHandlerService) errorHandlerService: IErrorHandlerService;

    protected get errorHandler(): any {
        return this.errorHandlerService;
    }
    //
    // VerificationRequest(data: ITargetRequest): Observable<any> {
    //     return this.put('/', {
    //         type: 'VerificationRequest',
    //         data: data
    //     })
    // }
    //
    // VerificationValidation(data: any): Observable<any> {
    //     return this.put('/', {
    //         type: 'VerificationValidation',
    //         data: data
    //     })
    // }

    TOTPRequest(data: ITargetRequest): Observable<any> {
        return this.put('/', {
            type: 'TOTPRequest',
            data: data
        })
    }

    TOTPValidation(data: IValidationRequest): Observable<any>  {
        return this.put('/', {
            type: 'TOTPValidation',
            data: data
        })
    }
}
