import React from 'react';
import Translate from '../Translate';
import {connect} from 'react-redux';
import { RootState } from '../../../store/reducers';
import { useInjection } from '../../../ioc';
import { ErrorHandlerService } from '../../../service';

export const CancelButton= () => {
    const errorHandlerService = useInjection(ErrorHandlerService);
    function cancel(){
        errorHandlerService.handleGenericError(
            {
                code: "OPERATION_CANCELLED_BY_USER"
            }, true
        );
    }

    return (
        <div className="btn btn-secondary mt-20"
              onClick={cancel}>
            <Translate text={`button.cancel`}/>
        </div>
    );
};

export default connect(
    (state: RootState) => {},{}
)(CancelButton);