import { ITspaySettings } from "../model/ITspaySettings";

export const QUERY_PARAM = '_d';

// queryString util
function encodeToBase64Url(value: string) { 
    return btoa(value).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

function decodeFromBase64Url(value: string) {
    return atob(value.replace(/-/g, '+').replace(/_/g, '/'));
}

export function getQueryParams(): ITspaySettings | undefined {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const query = params.get(QUERY_PARAM);
    if (query) {
        try {
            return JSON.parse(decodeFromBase64Url(query));
        } catch (e) {
            return undefined;
        }
    }
}

export function addQueryParams(baseUrl: string, tspaySetting: ITspaySettings): string {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    params.set(QUERY_PARAM, encodeToBase64Url(JSON.stringify(tspaySetting)));
    return`${baseUrl}?${params}`;
}