import {injectable} from 'inversify';
import store from '../store';
import {changeActiveStep, changeError} from '../store/reducers/sagaSlice';
import {changeRedirectData} from '../store/reducers/linkDataSlice';
import {LinkStep} from '../model/LinkStep';
import {ErrorType} from '../model/IError';
import {getGenericLocale, getTranslatedError, IJson} from '../core';
import stripeDeclineCode from '../core/stripe-decline-code.json';
import {DEFAULT_LOCALE} from '../constants/locales';

export interface IErrorHandlerService {
    handleStripeError(error: any, intentId: string, expectKey?: string): void;
    handleInternalError(message: string): void;
    handleRestApiError(error: any): void;
}

@injectable()
export class ErrorHandlerService implements IErrorHandlerService {

    handleStripeError(error: any, intentId: string, expectKey?: string): void {
        console.log(error);

        // if (error.code) {
        //     store.dispatch(changeRedirectData({
        //         state: error.code,
        //         stripeId: intentId,
        //     }));
        // } else {
            store.dispatch(changeRedirectData({
                state: 'expectation_error',
                stripeId: intentId,
                expectKey,
            }));
        // }

        this.changeAndTranslateError(error)
    }

    handleGenericError(error: any, canceled: boolean = false): void {
        store.dispatch(changeRedirectData({
            state: 'expectation_error',
            stripeId: 'stripeId',
            canceled: canceled
        }));

        this.changeAndTranslateError(error, false);
    }

    changeAndTranslateError(error: any, stripeError: boolean = true): void {

        // try to get translated italian message from composed stripe error key
        let errorKey = undefined;
        if(stripeError) {
            errorKey = typeof error?.code === 'string' ? `STP_${error.code.toUpperCase()}` : undefined;
        } else {
            errorKey = typeof error?.code === 'string' ? `${error.code.toUpperCase()}` : undefined;
        }

        let message: string | undefined;

        if (typeof errorKey === 'string') {
            const errorFound = this.getTranslatedError(errorKey);
            message = errorFound?.message;
        }

        let declineErrorTranslatedFromStripe = undefined;
        let declineErrorTranslatedGeneric = undefined;
        
        if(typeof error?.decline_code === 'string'){
            declineErrorTranslatedFromStripe = this.getTranslatedDecline(error.decline_code)
        }

        if(!declineErrorTranslatedFromStripe && errorKey){
            const locale = this.getActiveLocale();
            declineErrorTranslatedGeneric = getTranslatedError(
                locale,
                errorKey,
            );
        }
        store.dispatch(changeError({
            message,
            type: stripeError ? ErrorType.Stripe : ErrorType.Internal,
            errorDetails: {
                errorKey,
                description: declineErrorTranslatedFromStripe?.description ? declineErrorTranslatedFromStripe.description : declineErrorTranslatedGeneric?.description,
                declineCode: error?.decline_code,
            }
        }));
        store.dispatch(changeActiveStep(LinkStep.Error));
    }

    handleInternalError(message: string): void {
        console.log(message);

        store.dispatch(changeError({
            // this message is not translated, cannot pass it to the error page
            // message: message,
            message: undefined,
            type: ErrorType.Internal,
        }));
        store.dispatch(changeActiveStep(LinkStep.Error));
    }

    /**
     * get active locale based on state
     */
    getActiveLocale() {
        const currentState = store.getState();

        return getGenericLocale(currentState?.saga?.language || DEFAULT_LOCALE);
    }

    /**
     * get translated decline payload based on langLocale used
     */
    getTranslatedDecline(declineCode: string) {
        const availableDeclineCodeList = stripeDeclineCode;

        // find matching decline
        const matchingDecline = availableDeclineCodeList.find((d) => d.code === declineCode);
        if (!matchingDecline) {
            return undefined;
        }

        // get active locale
        const locale = this.getActiveLocale();

        // translate also nextStep
        let nextStep: string | undefined;
        if (
            typeof matchingDecline?.nextStep === 'object' &&
            locale in matchingDecline.nextStep &&
            matchingDecline.nextStep[locale] !== ''
         ) {
            nextStep = matchingDecline.nextStep[locale]
         }

        return {
            raw: matchingDecline,
            code: declineCode,
            description: matchingDecline.description[locale],
            nextStep, 
        }

    }

    /**
     * by providing an errorKey and errorMessagePayload returned translated
     * error based on langLocale used
     */
    getTranslatedError(errorKey: string, payload: IJson = {}) {
        const locale = this.getActiveLocale();
        return getTranslatedError(
            locale,
            errorKey,
            payload,
        );
        /*
        const availableErrors = errorMapping;

        // check if we have a matching of errorKey
        if (
            !(errorKey in availableErrors)
        ) {
            return undefined;
        }

        // ~~~ matching errorKey found, going to translate the message
        const matchError = availableErrors[errorKey as keyof typeof availableErrors];

        console.log('matching error found', matchError);

        const locale = this.getActiveLocale();

        // add extra checks for infering/ts
        const templateMessage = locale in matchError.message ? matchError.message[locale as 'en'] : matchError.message.en;


        const renderedMessage = mustacheRender(templateMessage, payload);

        return {
            raw: matchError,
            message: renderedMessage,
        }*/
    }

    handleRestApiError(error: any): void {
        console.log('handle REST API error', error)
        console.log(error.response.data);

        const errorKey: string | undefined = error?.response?.data?.errorKey;
        const errorMessagePayload: IJson | undefined = error?.response?.data?.errorMessagePayload ? error?.response?.data?.errorMessagePayload : error?.response?.data?.payload ? error?.response?.data?.payload : undefined;

        const translatedError = typeof errorKey === 'string' ?
                                     this.getTranslatedError(errorKey, errorMessagePayload) :
                                     this.getTranslatedError("PW_GENERIC_ERROR");

        // set translated error if present or fallback to generic one
        let message: string = typeof translatedError?.message === 'string' ?
            translatedError.message : 'Spiacente, si è verificato un errore. Ti preghiamo di riprovare.';
        
        // if customErrorTitle is undefined the error title won't be shown
        // if customErrorTitle is populalted with a string it will be used as custom error title
        let customErrorTitle: string | undefined;

        /*
        // OLD CODE START
        // ~~~ legacy error code managing
        // customize displayed error message based on errorType (if recevied)
        switch(error?.response?.data?.errorType) {
            case 'ExceededPaymentAllocationException':
            case 'LinkAlreadyExecutedError':
                message = 'Grazie, hai già effettuato questo pagamento.'
                break;
            case 'LinkExpiredError':
            case 'LinkExpiredException':
                message = 'Spiacente, questo link non è più utilizzabile.'
                break;
            // fallback to this message if unknown error type
            default:
                message = 'Spiacente, si è verificato un errore. Ti preghiamo di riprovare.'
                break;
        }

        // ~~~ overwrite error message based on new errorKey
        // customize displayed error message based on errorKey (if recevied)
        switch(error?.response?.data?.errorKey) {
            case 'CHARGE_ORDER_MAX_NUMBER_EXCEEDED':
            case 'LINK2PAY_ALREADY_EXECUTED':
                message = 'Grazie, hai già effettuato questo pagamento.'
                break;
            case 'SOURCE_ORDER_MAX_AMOUNT_EXCEEDED':
            case 'SOURCE_ORDER_MAX_NUMBER_EXCEEDED':
            case 'LINK2SAVE_ALREADY_EXECUTED':
                message = 'Hai raggiunto il numero massimo di utilizzi per il link.'
                break;
            default:
                break;
        }
        // END OLD CODE
        */

        store.dispatch(changeError({
            message: message,
            type: ErrorType.RestApi,
            customErrorTitle,
        }));
        store.dispatch(changeActiveStep(LinkStep.Error));
    }
}
