import React, {FC} from 'react';

import {connect} from 'react-redux';
import {Redirect} from 'react-router';
import PaymentDetails from '../PaymentDetails';
import TsPayRedirect from '../../Common/Redirect';
import {RootState} from '../../../store/reducers';
import {orderSelector} from '../../../store/selectors/linkDataSelectors';
import {activeStepSelector, languageSelector} from '../../../store/selectors/sagaSelectors';
import {changeActiveStep} from '../../../store/reducers/sagaSlice';
import {IOrder} from '../../../model/IOrder';
import {LinkStep} from '../../../model/LinkStep';
import Translate from '../../Common/Translate';
import {useInjection} from '../../../ioc';
import {ErrorHandlerService} from '../../../service';
import { Config } from '../../../core';

export interface IStepManagerProps {
    order: IOrder | null;
    language: string;
    activeStep: LinkStep | null;
    changeActiveStep: typeof changeActiveStep;
}

const StepManager: FC<IStepManagerProps> = (props: IStepManagerProps) => {

    const errorHandlerService = useInjection(ErrorHandlerService);

    // TODO manage publishableKey. why is this required for source?
    //if (!props.order || !props.activeStep || !props.order.publishableKey) {
    if (!props.order || !props.activeStep) {
        return (<Translate text={'loading'}/>);
    }

    const getActiveStep = (activeStep: LinkStep) => {
        switch(activeStep) {
            case LinkStep.PaymentDetails:
                return <PaymentDetails />;
            case LinkStep.Redirect:
                return <TsPayRedirect />;
            case LinkStep.Error:
                return <Redirect to={'/error'}/>;
            default:
                errorHandlerService.handleInternalError(`[StepManager]: active step name is invalid:${activeStep}`);
                return null;
        }
    };
    const config = Config();

    return (
        <div>
            <div className="content">
            { getActiveStep(props.activeStep) }
            </div>
        </div>
    );
};

export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state),
            activeStep: activeStepSelector(state),
            language: languageSelector(state)
        };
    },
    {
        changeActiveStep
    }
)(StepManager);
