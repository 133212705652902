import React, { FC } from 'react';
import styles from "../../../styles.module.scss";
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import { languageSelector } from '../../../store/selectors/sagaSelectors';
import {orderSelector} from '../../../store/selectors/linkDataSelectors';
import {IOrder} from '../../../model/IOrder';


export interface TspayDetailIconProps {
    order: IOrder | null;
}
const TspayDetailIcon:FC<TspayDetailIconProps> = (props: TspayDetailIconProps) => {
    //private detailUrl = 'www.google.it?test='+this.props.order?.type;//'https://www.teamsystempayments.com/ts-pay';
    if (!props.order || !props.order.type) {
        return null;
    }
    const detailUrl = props.order.type === 'payme' ?
        'https://www.teamsystempayments.com/ts-pay/pos-digitale' :
        'https://www.teamsystempayments.com/ts-pay';

    return (
        <a className={styles.helpIcon} href={detailUrl} target="_blank"></a>
    );
}


export default connect(
    (state: RootState) => {
        return {
            language: languageSelector(state),
            order: orderSelector(state)
        };
    },
    {}
)(TspayDetailIcon);