import React, {FC} from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {activeStepSelector, languageSelector} from '../../../store/selectors/sagaSelectors';
import {orderSelector} from '../../../store/selectors/linkDataSelectors';
import {IOrder} from '../../../model/IOrder';
import Translate from '../../Common/Translate';
import StepManager from '../StepManager';
import { Config } from '../../../core';
import { LinkStep } from '../../../model/LinkStep';

export interface IStepManagerProps {
    order: IOrder | null;
    language: string;
    activeStep: LinkStep | null;
}

const LinkHost: FC<IStepManagerProps> = (props: IStepManagerProps) => {

    const publishableKey = props.order?.acquire?.stp.publishableKey;
    if (!props.order || !publishableKey) {
        return (<Translate text={'loading'}/>);
    }
    
    const config = Config();

    return (
        <div>
            <div className="content">
                <StepManager/>
            </div>
        </div>
    );
};

export default connect(
    (state: RootState) => {
        return {
            order: orderSelector(state),
            language: languageSelector(state),
            activeStep: activeStepSelector(state),
        };
    },
    {}
)(LinkHost);
