export enum LanguageLocale {
  EN = "en-US",
  IT= "it-IT"
}

export enum LanguageLocaleType {
  en = "en-GB",
  it = "it-IT"
}

export const DEFAULT_LOCALE = 'it';
export const DEFAULT_LANG_LOCALE = LanguageLocale.IT;
