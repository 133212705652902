import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import Translate from '../Translate/index';
import styles from './styles.module.scss';


interface IPaymentMethodOptionProps {
    style?: any;
    icon: string
    title?: string;
    action: any;
}

class PaymentMethodOption extends React.Component<IPaymentMethodOptionProps> {

    renderTitle() {
        if(this.props.title) {
            return (
                <span className={styles.title}>
                    <Translate text={this.props.title}/>
                </span>
            );
        }
    }

    render() {
        return (
            <div className={`${styles.pmoContainer} ${this.props.style}`} onClick={this.props.action}>
                <div className={this.props.icon}/>
                {this.renderTitle()}
            </div>
        );
    }
}

export default (PaymentMethodOption);