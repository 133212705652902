import {inject, injectable} from 'inversify';
import axios, {AxiosResponse} from 'axios';
import {IJson, Config} from '../../core';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, first, filter} from 'rxjs/operators';
import {ErrorHandlerService, IErrorHandlerService} from '../errorHandlerService';

/**
 * A Simple Rest Service interface exposing only get method
 */
export interface IRestGetService<T> {
    get(url: string): Observable<T | null>;
    post(url: string, data: IJson): Observable<T | null>;
    put(url: string, data: IJson): Observable<T | null>;
    delete(url: string): Observable<T | null>;
    query(url?: string): Observable<Array<T> | null>;
}

@injectable()
export abstract class AbstractRestGetService<T> implements IRestGetService<T> {

    abstract rootUrl: string;

    @inject(ErrorHandlerService) protected errorHandler: IErrorHandlerService;

    private config = Config();

    post(url: string, data: IJson): Observable<T | null> {
        const resp = axios.post<T>(this.getUrl(url), data);
        return this.createResponse<T>(resp);
    }

    put(url: string, data: IJson): Observable<T | null> {
        const resp = axios.put<T>(this.getUrl(url), data);
        return this.createResponse<T>(resp);
    }

    get(url: string): Observable<T | null> {
        const resp = axios.get<T>(this.getUrl(url));
        return this.createResponse<T>(resp);
    }

    query(url: string = '/'): Observable<Array<T> | null> {
        const resp = axios.get<Array<T>>(this.getUrl(url));
        return this.createResponse<Array<T>>(resp);
    }

    delete(url: string): Observable<T | null> {
        const resp = axios.delete<T>(this.getUrl(url));
        return this.createResponse<T>(resp);
    }

    /**
     * Generate url for given resource
     *
     * @param url 
     */
    protected getUrl(url: string = ''): string {
        //TODO: this is just an hotfix, remove it
        let dataUrl = this.config.dataUrl;
        if(url.startsWith('stp/')) {
            dataUrl = dataUrl.replace('/web','/stp');
            url = url.replace('stp/','');
        }
        return dataUrl + this.rootUrl + url;
    }

    /**
     * Wrapper allowing the response to be used as react hook
     * @param resp 
     */
    protected createResponse<O>(response: Promise<AxiosResponse>): Observable<O | null> {
        const p = response.then(resp => resp.data as O);
        return from(p).pipe(
            first(),
            catchError((httpError: any) => {
                // if error key is RATE_LIMIT use a custom error handling
                // on the UI
                // ref https://gitlab.com/tspay/pay-webapp/-/issues/80
                if (
                    httpError &&
                    httpError?.response?.data &&
                    httpError?.response?.data?.errorKey === 'RATE_LIMIT'
                ) {
                    // throw throwError(httpError);
                    httpError.error = true;
                    return Promise.resolve(httpError);
                }

                this.errorHandler.handleRestApiError(httpError);
                return of(null);
            }),
            filter((e: any) => !!e)
        )
    }
}
