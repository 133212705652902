// storage util
import { compress, decompress } from 'lz-string';
import { ITspaySettings } from '../model/ITspaySettings';

export const LANGUAGE = 'tspay_lang';

export const setLocalStorage = (key: string, items: any) => {
    localStorage.setItem(key, compress(JSON.stringify(items)));
}

export const getLocalStorage = (key: string) => {
    let items = localStorage.getItem(key);
    if (items) {
        return JSON.parse(decompress(items));
    } else {
        return null;
    }
}
