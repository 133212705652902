import React from 'react';
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAsterisk } from '@fortawesome/free-solid-svg-icons'
import TooltipInfo from '../../Tooltip/TooltipInfo'
import { SchedulePlan } from '../../../../model/IOrder';
import Translate from '../../Translate';
import Moment from "react-moment";

export enum TooltipIconSize {
    Small = 'small',
    Big = 'big',
}

export enum TooltipType {
    Web = 'web',
    Mobile = 'mobile',
}

interface ISchedulePlanTooltipProps {
    className: string
    schedulePlan: SchedulePlan
}

interface ITooltipState {
    showInfo: boolean,
    type: TooltipType,
}

class SchedulePlanTooltip extends React.Component<ISchedulePlanTooltipProps, ITooltipState> {

    constructor(props: ISchedulePlanTooltipProps) {
        super(props);

        this.state = {
            showInfo: false,
            type: TooltipType.Web,
        }
    }

    componentWillMount() {
        this.onResize();
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    render() {
        return (
            <div className={styles.schedulePlanTooltip}>
                <FontAwesomeIcon className={this.props.className} icon={faAsterisk}
                     onMouseEnter={this.onMouseAction}
                     onMouseLeave={this.onMouseAction}
                     onClick={this.onClick}/>
                { this.renderInfo() }
            </div>
        )
    }

    private renderInfo() {
        if (!this.state.showInfo) {
            return null;
        }

        return (<TooltipInfo
                    header={<Translate text={`tooltip.paymentSchedulePlan.header`} />}
                    content={<Moment format={"DD/MM/YYYY"} parse="YYYYMMDD">{this.props.schedulePlan.originalChargeDate}</Moment>} type={this.state.type}/>)
    }

    private onMouseAction = () => {
        if (this.state.type !== TooltipType.Web) {
            return;
        }

        this.setState({showInfo: !this.state.showInfo})
    };

    private onClick = () => {
        if (this.state.type !== TooltipType.Mobile) {
            return;
        }

        this.setState({showInfo: !this.state.showInfo})
    };

    private onResize = () => {
        if (window.innerWidth > 992) {
            this.setState({
                type: TooltipType.Web,
                showInfo: false
            });
            return;
        }

        this.setState({type: TooltipType.Mobile});
    }
}

export default SchedulePlanTooltip;