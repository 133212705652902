export enum ErrorType {
    Stripe = 'stripe',
    Internal = 'internal',
    RestApi = ' restApi',
}

export interface IError {
    message?: string;
    type: ErrorType;
    customErrorTitle?: string;
    errorDetails?: {
        declineCode?: string;
        errorKey?: string;
        description?: string;
    }
}