import { RootState } from "../reducers";
import { createSelector } from "reselect";
import {ILinkDataState} from '../reducers/linkDataSlice';

export const selectLink = (state: RootState): ILinkDataState => {
  return state.linkData;
};

export const orderSelector = createSelector(
    [selectLink],
    (state: ILinkDataState) => state.order
);

export const credentialSelector = createSelector(
    [selectLink],
    (state: ILinkDataState) => state.credential
);

export const contactEmailSelector = createSelector(
    [selectLink],
    (state: ILinkDataState) => state.contactEmail
);

export const customerNameSelector = createSelector(
    [selectLink],
    (state: ILinkDataState) => state.customerName
);

export const customerAddressSelector = createSelector(
    [selectLink],
    (state: ILinkDataState) => state.customerAddress
);

export const customerIdSelector = createSelector(
    [selectLink],
    (state: ILinkDataState) => {
        // return undefined instead of null if customer id is not set
        if (!state.customerId) {
            return undefined;
        }

        return state.customerId;
    }
);

export const savePaymentMethodSelector = createSelector(
    [selectLink],
    (state: ILinkDataState) => state.savePaymentMethod
);

export const paymentMethodTypeSelector = createSelector(
    [selectLink],
    (state: ILinkDataState) => state.paymentMethodType
);

export const paymentMethodSelector = createSelector(
    [selectLink],
    (state: ILinkDataState) => state.paymentMethod
);

export const customerPaymentMethodsSelector = createSelector(
    [selectLink],
    (state: ILinkDataState) => state.customerPaymentMethods
);

export const redirectDataSelector = createSelector(
    [selectLink],
    (state: ILinkDataState) => state.redirectData
);

export const validUntilSelector = createSelector(
    [selectLink],
    (state: ILinkDataState) => state.validUntil
);

export const payMeOrderAmountSelector = createSelector(
    [selectLink],
    (state: ILinkDataState) => state.payMeOrderAmount
);

export const chargePaymentRefSelector = createSelector(
    [selectLink],
    (state: ILinkDataState) => state.chargePaymentRef
);

export const transactionCodeSelector = createSelector(
    [selectLink],
    (state: ILinkDataState) => state.transactionCode
);