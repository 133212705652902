import React from 'react';
import {inject} from 'inversify';
import {ErrorHandlerService} from '../../../service';
import {IErrorHandlerService} from '../../../service/errorHandlerService';

interface ICurrencySymbolProps {
    currency?: string;
}

/**
 * Return currency symbol according. Default is $
 */
class CurrencySymbol extends React.Component<ICurrencySymbolProps> {

    @inject(ErrorHandlerService) private errorHandlerService: IErrorHandlerService;

    render() {
        let currency: string = '';
        switch(this.props.currency) {
            case 'EUR':
                currency = '€';
                break;

            case 'USD':
                currency = '$';
                break;

            default:
                currency = '$';
                return;
        }

        return `${currency}`;
    }
}

export default CurrencySymbol;