import { combineReducers } from "redux";
import sagaSlice from "./sagaSlice";
import linkDataSlice from './linkDataSlice';


const rootReducer = combineReducers({
  saga: sagaSlice,
  linkData: linkDataSlice
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
